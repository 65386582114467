import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countup'
})
export class CountupPipe implements PipeTransform {

  transform(text: string, args?: number) {
    let length = text.length;
    if (length > 200)
      return 200;
    else
      return (length);
  }

}
