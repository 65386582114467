import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { Workflow } from 'src/app/models/workflow';
import { BsModalRef } from 'ngx-bootstrap';
import { WorkflowService } from 'src/app/services/workflow.service';
import { AlertifyService } from 'src/app/services/alertify.service';
import { Form, FormGroup, FormControl, Validators, FormBuilder, ValidationErrors } from '@angular/forms';
import { ResourceService } from 'src/app/services/resource.service';
import { ResourceType } from 'src/app/models/resourceType';
import { Locale } from 'src/app/models/locale';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-resource-modal',
  templateUrl: './resource-edit-modal.component.html',
  styleUrls: ['./resource-edit-modal.component.scss']
})
export class ResourceEditModalComponent implements OnInit, OnDestroy, AfterViewInit, OnDestroy {
  isEditMode: boolean;
  resource: SelfHelpResources.Resource;
  resourceForm: FormGroup;
  title: string;
  resourceTypes: ResourceType[];
  workflows: Workflow[];
  validationErrors: string;
  localeId: number;
  localeName: string;
  locales: Locale[];

  private resourceLocales: Locale[] = [];
  localesSub: Subscription;

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private workflowService: WorkflowService,
    private resourceService: ResourceService,
    private alertifyService: AlertifyService) { }

  ngOnInit() {
    this.InitResourceForm();
    if (!this.isEditMode) {
      this.resource = new SelfHelpResources.Resource();
    } else {
      console.log(this.resource);
      this.resourceForm.get('id').setValue(this.resource.selfhelpResourcesId);
      this.resourceForm.get('name').setValue(this.resource.title);
      this.resourceForm.get('workflowId').setValue(this.resource.resourceId);
      this.resourceForm.get('resourceTypeId').setValue(this.resource.typeId);
      this.resourceForm.get('url').setValue(this.resource.url);
      this.resourceForm.get('description').setValue(this.resource.description);
    }

    this.localesSub = this.resourceService.getLocales().subscribe(locales => {
      this.locales = locales;
      this.localeName = this.locales.find(l => l.id == this.localeId).name;

      let newLocale = new Locale();
      newLocale.id = this.localeId;
      newLocale.name = this.localeName;

      this.resourceLocales.push(Object.assign({}, newLocale));
    });
  }

  isChecked(localeId, localeName) {

    if (localeId == this.localeId)
      return true;
    else
      return false;
  }

  onChange(event) {
    let localeId = +event.srcElement.value;
    let localeName = event.srcElement.name;
    let isChecked = event.srcElement.checked;

    if (!isChecked) {
      this.resourceLocales = this.resourceLocales.filter((tr) => tr.id != localeId)
    }
    else {
      let newLocale = new Locale();
      newLocale.id = localeId;
      newLocale.name = localeName;

      this.resourceLocales.push(Object.assign({}, newLocale));
    }


  }
  ngAfterViewInit() {

    if (this.isEditMode) {
      Object.keys(this.resourceForm.controls).forEach(key => {

        this.resourceForm.controls[key].updateValueAndValidity();
        this.resourceForm.controls[key].markAsDirty();
      });
    }
    else {
      Object.keys(this.resourceForm.controls).forEach(key => {
        this.resourceForm.controls[key].markAsDirty();
        this.resourceForm.controls[key].updateValueAndValidity();
      });
    }
  }

  submit() {
    if (!this.isEditMode)
      this.addNewResource();
    else {
      this.updateResource();
    }
  }

  filterChars(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  InitResourceForm() {

    let exp = new RegExp('(https?|http)://(www\d?|[a-zA-Z0-9]+)?\.[a-zA-Z0-9-]+(\:|\.)([a-zA-Z0-9.]+|(\d+)?)([/?:].*)?');

    this.resourceForm = this.fb.group({
      id: ['', null],
      workflowId: [null, Validators.required],
      name: ['', [Validators.required, Validators.min(4)]],
      resourceTypeId: [null, Validators.required],
      Url: ['', [Validators.required, Validators.pattern(exp)]],
      description: ['', [Validators.required, Validators.minLength(200)]]
    });
  }

  getFormValidationErrors() {
    Object.keys(this.resourceForm.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.resourceForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          this.validationErrors += ' Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]
        });
      }
    });
  }

  addNewResource() {

  }


  updateResource() {

  }

  onKeyDown(event) {
    let key;
    key = event.charCode;  //

    if (event.keyCode == 13) {
      if (this.resourceForm.valid)
        this.submit();
      this.bsModalRef.hide();
    }
    else {
      event.stopPropagation();
    }
  }


  ngOnDestroy(): void {
    if (this.resource)
      this.resource = null;

    if (this.localesSub)
      this.localesSub.unsubscribe();
  }
}
