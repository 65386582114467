import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AlertifyService } from '../services/alertify.service';
import { Form, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/public_api';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { State } from '../models/state';
import { Subscription } from 'rxjs';
import { LookupService } from '../services/lookup.service';
import { City } from '../models/city';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  user: User;
  registerForm: FormGroup;
  @Output()
  cancelRegister = new EventEmitter();
  //adding Partial keyword allows to override only preffered setttings
  //makes rest optional
  bsConfig: Partial<BsDatepickerConfig>
  states:State[];
  cities:City[];
  selectedState:State;
  citiesSub:Subscription;
  statesSub:Subscription;
  isdisabled:boolean = false;
  constructor(private lookupService:LookupService,private authService: AuthService,private router:Router,
    private alertifyService: AlertifyService, private fb: FormBuilder) { }

  ngOnInit() {

    this.bsConfig = {
      containerClass: 'theme-red',
      dateInputFormat: 'MM/DD/YYYY'
    }
    this.InitRegisterForm();
  }
  
  InitRegisterForm() {
    this.registerForm = this.fb.group({
      gender: ['male'],
      username: ['', Validators.required],
      dateOfBirth: [null, Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      state: ['', Validators.required],
      email:['',[Validators.required,Validators.email]],
      password: ['', [Validators.required,
      Validators.minLength(4),
      Validators.maxLength(8)]],
      confirmPassword: ['', [Validators.required]]
    },
      { validator: this.passwordMatchValidator })

    this.statesSub = this.lookupService.getStates().subscribe(states=>{
      this.states = states;
    })
  } 

  passwordMatchValidator(g: FormGroup) {
    return g.get('password').value === g.get('confirmPassword').value ?
      null : { 'mismatch': true };
  }

  register() {

    if (this.registerForm.valid) {
      //Object.assign clones property values and maps to new object
      this.user = Object.assign({},this.registerForm.value);

      
      this.authService.register(this.user).subscribe(()=>{
        this.alertifyService.success('Registration successful');
      
      },error=>{
        this.alertifyService.error(error);
      },()=>{
        this.authService.login(this.user).subscribe(()=> {
          this.sendEmailConfirmation(this.user);
          this.router.navigate(['/members']);
        });
      }
      );
    }
  }
  cancel() {
    console.log('cancelled');
    this.cancelRegister.emit(false);
  }

  sendEmailConfirmation(user) {
    if (user.email.length >= 4) {
      this.authService.validateEmailAddress(user).pipe(take(1)).subscribe((user: User) => {

        return this.authService.sendConfirmationEmail(user).pipe(take(1)).subscribe(result => {

          this.alertifyService.success("Email Confirmation Sent");
        });
      })
    }
  }

  ngOnDestroy() {
    if (this.statesSub) {
      this.statesSub.unsubscribe();
    }
    if (this.citiesSub) {
      this.citiesSub.unsubscribe();
    }
  }
}
