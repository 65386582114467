import { OnInit, Output, Component, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

import { Tag } from 'src/app/models/tag';
import { TaggedResource } from 'src/app/models/taggedResource';
import { ResourceService } from 'src/app/services/resource.service';
import { TaggedResourceService } from 'src/app/services/taggedresource.service';
import { Subscription } from 'rxjs';
import { TaggedResources } from 'src/app/models/taggedResources';
import { TagService } from 'src/app/services/tag.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-resources-modal',
  templateUrl: './resources-modal.component.html',
  styleUrls: ['./resources-modal.component.scss']
})
export class ResourcesModalComponent implements OnInit, OnDestroy {
  workflowId: number;
  resource: SelfHelpResources.Resource;
  tags: Tag[];
  tagsResult: Tag[];
  taggedResources: TaggedResource[];
  updateTaggedResourcesSub: Subscription;

  constructor(public bsModalRef: BsModalRef,
              private tagService: TagService,
              private resourceService: ResourceService,
              private langService: LangService,
              private taggedResourceService: TaggedResourceService) { }

  ngOnInit() {
    this.tagsResult = this.tags;
    this.getResourceTags();
    this.tagService.onRefresh.subscribe(res => {
      this.onRefreshTags();
    });
    this.resourceService.onRefresh.subscribe(res => {
      this.onRefreshResources();
    });
  }

  onChange(event) {
    const tagId = +event.srcElement.value;
    const isChecked = event.srcElement.checked;
    if (!isChecked) {
      this.taggedResources = this.taggedResources.filter((tr) => tr.tagId !== tagId);
    } else {
      const newTaggedResource = new TaggedResource();
      newTaggedResource.selfhelpTaggedResourcesId = 0;
      newTaggedResource.workflowId = this.workflowId;
      newTaggedResource.resourceId = this.resource.resourceId;
      newTaggedResource.tagId = tagId;
      this.taggedResources.push(Object.assign({}, newTaggedResource));
    }
  }

  onClear() {
    this.taggedResources = [];
  }

  submit() {
    this.updateTaggedResourcesSub = this.taggedResourceService.
      updateResourcesTagged(this.workflowId, this.resource.resourceId, this.taggedResources).subscribe(res => {
        this.tagsResult = this.tags;
        this.bsModalRef.hide();
        this.resourceService.refresh();
      });
  }

  isChecked(tagId: any) {
    return !!(this.taggedResources.find(tr => tr.tagId === tagId));
  }

  getResourceTags() {
    this.taggedResourceService.getResourceTags(this.workflowId, this.resource.selfhelpResourcesId).subscribe(tags => {
      this.taggedResources = tags;
    });
  }

  onRefreshTags() {
    this.getResourceTags();
    this.tagService.getTags(this.workflowId, this.langService.getLangCode(), false).subscribe(data => {
      this.tags = data;
      this.tagsResult = data;
    });
  }

  onRefreshResources() {
    this.getResourceTags();
    this.resourceService.getResource(this.resource.selfhelpResourcesId).subscribe(res => {
      this.resource = res;
    });
  }


  updateFilter(val: any) {
    const searchCharArray = val.split('');
    if (!searchCharArray || searchCharArray.length < 1) {
      this.onRefreshTags();
    } else {
      const value = val.toString().toLowerCase().trim();
      if (this.tags.length > 0) {
        // get the key names of each column in the dataset
        const keys = Object.keys(this.tags[0]);
        if (keys.length > 0) {
          // get the amount of columns in the table
          const count = keys.length;
          // assign filtered matches to the active datatable
          this.tags = this.tagsResult.filter(item => {
            // iterate through each row's column data
            for (let i = 0; i < count; i++) {
              // check for a match
              if (
                (item[keys[i]] &&
                  item[keys[i]]
                    .toString()
                    .toLowerCase()
                    .indexOf(value) !== -1) ||
                !value
              ) {
                // found match, return true to add to result set
                return true;
              }
            }
          });
        }
      }
    }
  }

  onKeyDown(event, tagsForm) {
    let key;
    key = event.charCode;  //

    if (event.keyCode === 13) {
      if (tagsForm.valid) {
        this.submit();
      }
    } else {
      event.stopPropagation();
    }
  }

  ngOnDestroy() {
    if (this.updateTaggedResourcesSub) {
      this.updateTaggedResourcesSub.unsubscribe();
    }
  }
}

