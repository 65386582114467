import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { ResourceType } from '../models/resourceType';
import { Locale } from '../models/locale';
import { EnvironmentService, EnvVar } from 'src/app/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  private envVars: EnvVar = null;
  private resultsSubject = new BehaviorSubject<SelfHelpResources.Resource[]>(null);
  onResults = this.resultsSubject.asObservable();
  private refreshSubject = new Subject();
  onRefresh = this.refreshSubject.asObservable();

  constructor(private http: HttpClient,
              private envService: EnvironmentService) {
    this.envVars = this.envService.getEnvVars();
  }

  getResourceTypes(langCode: string): Observable<ResourceType[]> {
    const url = this.envVars.services_base_url + `/Content/getTypes?langCode=${langCode}`;
    return this.http.get<ResourceType[]>(url);
  }

  getResource(resourceId: number): Observable<SelfHelpResources.Resource> {
    return this.http.get<SelfHelpResources.Resource>(this.envVars.services_base_url + '/Resources/getResource/' + resourceId);
  }

  getResources(langCode: string): Observable<SelfHelpResources.Resource[]> {
    return this.http.get<SelfHelpResources.Resource[]>(this.envVars.services_base_url + '/Resources/getResources/' + langCode);
  }

  getLocales(): Observable<Locale[]> {
    return this.http.get<Locale[]>(this.envVars.services_base_url + '/Resources/getLocales');
  }

  getResults(tags: string[], workflowId: number, langCode: string): Observable<SelfHelpResources.Resource[]> {
    return this.http.post<SelfHelpResources.Resource[]>(this.envVars.services_base_url +
      '/Resources/getResults/' + workflowId + '/' + langCode, tags).pipe(tap(res => {
      this.resultsSubject.next(res);
      }));
  }

  refresh() {
    this.refreshSubject.next();
  }
}
