import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { Observable } from 'rxjs';

export class EnvVar {
  services_base_url = location.origin + '/api';
  chatbot_base_url = '';
  ga_tracking_id = '';
}
@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  private envVars: EnvVar = new EnvVar();

  constructor(private http: HttpClient) {
    this.initEnvVars().subscribe((e: EnvVar) => {
      this.envVars.ga_tracking_id = e.ga_tracking_id;
      this.envVars.chatbot_base_url = e.chatbot_base_url;
      console.log(this.envVars);
    });
  }

  public getEnvVars(): EnvVar {
    return this.envVars;
  }

  public initEnvVars(): Observable<EnvVar> {
    return this.http.get<EnvVar>(this.envVars.services_base_url + '/Environment/getEnvVars');
  }
}
