import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ContentService } from '../services/content.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  categories: SelfHelpResources.Category[];
  textsObject: SelfHelpResources.TextsObject;
  isFavorite = false;

  constructor(private contentService: ContentService,
              private router: Router,
              private route: ActivatedRoute) {
    this.contentService.getTexts().subscribe(result => {
      this.textsObject = result[0];
      // console.log(this.textsObject);
    });

    console.log(route.snapshot.url[0].path);
    this.isFavorite = ('favorites' === route.snapshot.url[0].path);
  }

  ngOnInit() {
    this.contentService.getCategories().subscribe(result => {
      this.categories = result.Category.sort((a, b) => (a.rankCat > b.rankCat) ? 1 : -1);
    });
  }

  onTriage() {
    alert('Guided Tour coming soon....');
  }

}
