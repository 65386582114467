import { Component, OnInit } from '@angular/core';
import { HistoryService } from 'src/app/services/history.service';
import { PageHistory } from 'src/app/models/pagehistory';
import { Router } from '@angular/router';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  constructor(private router:Router,private historyService:HistoryService) { }

  ngOnInit() {
  }


  onCategorySelected(category:any){

    this.router.navigate(['workflows/dissolution'])
    console.log(category);
  }
}
