import { Injectable } from '@angular/core';
import { PageHistory } from '../models/pagehistory';
import { Subject, Observable } from 'rxjs';
import { Workflow } from '../models/workflow';
import { HttpClient } from '@angular/common/http';
import { State } from '../models/state';
import { environment } from 'src/environments/environment';
import { City } from '../models/city';
import { EnvironmentService, EnvVar } from 'src/app/services/environment.service';


@Injectable({
  providedIn: 'root'
})
export class LookupService {
  private env_vars: EnvVar = null;

  constructor(private http: HttpClient,
    private _env_service: EnvironmentService) {
    this.env_vars = this._env_service.getEnvVars();
  }

  getStates(): Observable<State[]> {
    return this.http.get<State[]>(this.env_vars.services_base_url + '/citystatelookup/states');
  }

  getCities(state: string): Observable<City[]> {
    return this.http.get<City[]>(this.env_vars.services_base_url + '/citystatelookup/cities' + state);
  }
}
