import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { EmailMessage } from '../models/emailMessage';
import { AppConfig } from '../app.config';
import { UserService } from './user.service';
import { EnvironmentService, EnvVar } from 'src/app/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  userName: any;
  currentUser: User;
  appConfig: AppConfig;
  // BehaviorSubject allows us to setDefaultValue when communicating with
  // multiple components any component will be able to retreive currentValue.
  // Use this for inter component communication
  photoUrl = new BehaviorSubject<string>('../../assets/images/user.png');
  // We convert subject to a basic observable then subscribe to it
  currentPhotoUrl = this.photoUrl.asObservable();

  private envVars: EnvVar = null;
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private EnvService: EnvironmentService) {
    this.envVars = this.EnvService.getEnvVars();
    this.http.get<AppConfig>('../../assets/config/appconfig.json').pipe(take(1)).subscribe((appConfig: AppConfig) => {
      this.appConfig = appConfig;
    });
  }
  // This method triggers subject event and sets new photoUrl
  changeMemberPhoto(photoUrl: string) {
    this.photoUrl.next(photoUrl);
  }

  login(model: any) {
    return this.http.post(this.envVars.services_base_url + '/auth' + '/login', model)
      .pipe(
        map((response: any) => {
          const data = response;
          if (data) {
            localStorage.setItem('token', data.token);
            localStorage.setItem('user', JSON.stringify(data.user));
            this.decodedToken = this.jwtHelper.decodeToken(data.token);
            this.currentUser = data.user;
            this.userName = this.decodedToken.unique_name;
            // When user Login we call changeMemberPhoto method.
            this.changeMemberPhoto(this.currentUser.photoUrl);
          }
        })
      )
  }

  register(user: User) {
    return this.http.post(this.envVars.services_base_url + '/auth' + '/register', user);
  }

  sendConfirmationEmail(user) {
    return this.http.get('/assets/templates/emailconfirmation.html', { responseType: 'text' }).pipe(switchMap((template: string) => {

      const templateDictionary: { [key: string]: string } = {
        FIRSTNAME: user.firstName,
        LASTNAME: user.lastName,
        LOGOURL: location.origin.toString() + '/assets/images/corp-logo.jpg',
        CONFIRMURL: location.origin.toString() + this.appConfig.emailConfirmationPath + '?token=' + user.verificationToken + '&uid=' + user.id
      };

      const emailMessage = new EmailMessage();
      emailMessage.from = this.appConfig.smtpFrom;
      emailMessage.subject = this.appConfig.emailConfirmationSubject;
      emailMessage.to = user.email;
      emailMessage.includeTemplate = true;
      emailMessage.templateContent = template;
      emailMessage.templateDictionary = templateDictionary;

      return this.http.post(this.envVars.services_base_url + '/email/sendsmtp', emailMessage);

    }
    ));
  }

  sendResetPasswordEmail(user) {
    return this.http.get('/assets/templates/resetpassword.html', { responseType: 'text' }).pipe(switchMap((template: string) => {
      const templateDictionary: { [key: string]: string } = {
        FIRSTNAME: user.firstName,
        LASTNAME: user.lastName,
        LOGOURL: location.origin.toString() + '/assets/images/corp-logo.jpg',
        RESETURL: location.origin.toString() + this.appConfig.passwordResetPath + '?token=' + user.verificationToken + '&uid=' + user.id
      };

      const emailMessage = new EmailMessage();
      emailMessage.from = this.appConfig.smtpFrom;
      emailMessage.subject = this.appConfig.passwordResetSubject;
      emailMessage.to = user.email;
      emailMessage.includeTemplate = true;
      emailMessage.templateContent = template;
      emailMessage.templateDictionary = templateDictionary;

      return this.http.post(this.envVars.services_base_url + '/email/sendsmtp', emailMessage);
    })
    );
  }

  resetPassword(user) {
    return this.http.post<User>(this.envVars.services_base_url + '/auth' + '/resetpassword', user);
  }

  confirmEmail(user) {
    return this.http.post<User>(this.envVars.services_base_url + '/auth' + '/confirm/email', user);
  }

  loggedIn() {
    const token = localStorage.getItem('token');
    // isTokenExpired: If Expired Or Null Or Bad then returns true
    return !this.jwtHelper.isTokenExpired(token);
  }

  roleMatch(allowedRoles): boolean {
    let isMatch = false;
    if (allowedRoles) {
      if (this.decodedToken) {

        if (Array.isArray(this.decodedToken.role)) {
          let userRoles = this.decodedToken.role as Array<string>;

          if (userRoles) {
            userRoles = userRoles.map((item) => {
              return item.toLowerCase();
            });

            allowedRoles.forEach(element => {

              if (userRoles.includes(element.toLowerCase())) {
                isMatch = true;
                return;
              }
            });
          }
        } else {
          let userRole = this.decodedToken.role as string;
          if (userRole) {
            userRole = userRole.toLowerCase();
            allowedRoles.forEach(element => {

              if (userRole === (element.toLowerCase())) {
                isMatch = true;
                return;
              }
            });
          }
        }
      }
    }
    return isMatch;
  }

  verifyByEmailAddress(email: string): Observable<User> {
    const user: any = {
      email: email
    };
    return this.http.post<User>(this.envVars.services_base_url + '/auth' + '/verify/email', user);
  }

  verifyByUsername(username: string): Observable<User> {
    const user: any = {
      username: username
    };
    return this.http.post<User>(this.envVars.services_base_url + '/auth' + '/verify/username', user);
  }

  validateEmailAddress(user: User): Observable<User> {
    return this.http.post<User>(this.envVars.services_base_url + '/auth' + '/validate/email', user);
  }
}
