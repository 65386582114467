
import { OnInit, Output, Component, EventEmitter, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Tag } from 'src/app/models/tag';
import { TaggedResource } from 'src/app/models/taggedResource';
import { ResourceService } from 'src/app/services/resource.service';
import { TaggedResourceService } from 'src/app/services/taggedresource.service';
import { Subscription } from 'rxjs';
import { TaggedResources } from 'src/app/models/taggedResources';
import { TagService } from 'src/app/services/tag.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-tags-modal',
  templateUrl: './tags-modal.component.html',
  styleUrls: ['./tags-modal.component.scss']
})
export class TagsModalComponent implements OnInit {
  workflowId: number;
  tag: Tag;
  taggedResources: TaggedResource[] = [];
  topicResources: SelfHelpResources.TopicResource[] = [];
  resourceRows: SelfHelpResources.Resource[] = [];
  resourceResult: SelfHelpResources.Resource[] = [];
  updateTaggedResourcesSub: Subscription;
  @ViewChild('searchResource', { static: false })
  searchResource: any;

  constructor(public bsModalRef: BsModalRef,
              private tagService: TagService,
              private taggedResourceService: TaggedResourceService,
              private langService: LangService,
              private resourceService: ResourceService) {
              }

  ngOnInit() {
    this.resourceRows = this.sortResources();
    this.resourceResult = this.resourceRows;
    // console.log(this.workflowId);
    // console.log(this.tag);
    // console.log(this.taggedResources);
    // console.log(this.resourceRows);
  }

  sortResources(): SelfHelpResources.Resource[] {
    return this.resourceRows.sort(
      (a, b) => {
        const x = a.resourceId.substring(1);
        const y = b.resourceId.substring(1);
        const l = parseInt(x, 10);
        const r = parseInt(y, 10);
        return (l > r) ? 1 : -1;
      }
    );
  }

  onChange(event) {
    const resourceId = event.srcElement.value;
    const isChecked = event.srcElement.checked;

    if (!isChecked) {
      this.taggedResources = this.taggedResources.filter((tr) => tr.resourceId !== resourceId);
    } else {
      const newTaggedResource = new TaggedResource();
      newTaggedResource.selfhelpTaggedResourcesId = 0;
      newTaggedResource.workflowId = this.workflowId;
      newTaggedResource.resourceId = resourceId;
      newTaggedResource.tagId = this.tag.selfhelpTagsId;
      this.taggedResources.push(Object.assign({}, newTaggedResource));
    }
  }

  submit() {
    const resourceIds = this.taggedResources.map(({ resourceId }) => resourceId);

    this.updateTaggedResourcesSub = this.taggedResourceService.updateTaggedResources(this.workflowId,
      this.tag.selfhelpTagsId, resourceIds).subscribe(res => {
      this.bsModalRef.hide();
      this.tagService.refresh();
    });
  }

  onClear() {
    this.taggedResources = [];
  }

  isChecked(resourceId: any) {
    return !!(this.taggedResources.find(tr => tr.resourceId === resourceId));
  }

  updateFilter(val: any) {
    const searchCharArray = val.split('');
    if (!searchCharArray || searchCharArray.length  < 1 ) {
      this.onRefreshResources();
    } else {
      const value = val.toString().toLowerCase().trim();
      if (this.resourceRows.length > 0) {
        // get the key names of each column in the dataset
        const keys = ['title', 'resourceId']; // Object.keys(this.resourceRows[0]);
        if (keys.length > 0) {
          // get the amount of columns in the table
          const count = keys.length;
          // assign filtered matches to the active datatable

          this.resourceRows = this.resourceResult.filter(item => {
            // iterate through each row's column data
            for (let i = 0; i < count; i++) {
              // check for a match
              if (
                (item[keys[i]] &&
                  item[keys[i]]
                    .toString()
                    .toLowerCase()
                    .indexOf(value) !== -1) ||
                !value
              ) {
                // found match, return true to add to result set
                return true;
              }
            }
          });
        }
      }
    }
  }

  onRefreshResources() {
    this.resourceService.getResources(this.langService.getLangCode()).subscribe(data => {
      this.resourceRows = data;
      this.resourceRows = this.sortResources();
      this.resourceResult = this.resourceRows;
      this.resourceRows = [...this.resourceRows];
    });
  }

  onKeyDown(event, resourcesForm) {
    let  key;
    key = event.charCode;  //
    if (event.keyCode === 13) {
      if (resourcesForm.valid) {
       this.submit();
      }
    } else {
      event.stopPropagation();
    }
  }
}
