import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AlertifyService } from 'src/app/services/alertify.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Injectable({
    providedIn: 'root'
})
export class HttpExceptionInterceptor implements HttpInterceptor {
    constructor(private alertifyService: AlertifyService,
                private spinerService: SpinnerService) {
    }

    httpExceptionHandler(error: HttpErrorResponse) {
        const errorMessage = `${error.status}\nMessage: ${error.error}`;
        // this.alertifyService.error(errorMessage);
        alert(errorMessage);
        this.spinerService.cancel();
        return errorMessage;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    const errorMessage = this.httpExceptionHandler(error);
                    return throwError(errorMessage);
                })
            );
    }
}
