import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContentService } from '../services/content.service';
import { Workflow } from 'src/app/models/workflow';
import { WorkflowService } from 'src/app/services/workflow.service';
import { LangService } from 'src/app/services/lang.service';
import { HistoryService } from 'src/app/services/history.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  public registerMode = false;
  categories: SelfHelpResources.Category[];
  subCategory: Subscription;
  textsObject: SelfHelpResources.TextsObject;
  announcements: SelfHelpResources.Announcement[];
 
  guidedInterviewTitle = 'Which of these apply to you?';  // TODO: Add this to selfhelpTexts
  serviceGuideOn = false;
  guidedInterviewOn = true;
  workflowId = 0;

  workflows: Workflow[] = [];
  constructor(
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document: any,
    private http: HttpClient,
    private router: Router,
    private contentService: ContentService,
    private workflowService: WorkflowService,
    private historyService: HistoryService,
    private langService: LangService) {
    this.contentService.getTexts().subscribe(result => {
      this.textsObject = result[0];
      // console.log(this.textsObject);
    });
    this.contentService.getAnnouncements().subscribe(list => {
      this.announcements = list;
    });
    this.workflowService.getWorkflows(this.langService.getLangCode(), 1).subscribe(result => {
      this.workflows = result;
      // console.log(this.workflows);
    });
    this.subCategory = this.contentService.getCategories().subscribe(result => {
      this.categories = result.Category.sort((a, b) => (a.rankTile > b.rankTile) ? 1 : -1);
    });
  }

  ngOnInit() {
    const tabOn = this.historyService.getTabOn();
    if ('serviceGuideOn' === tabOn) {
      this.onServiceGuideOn();
    }
    if ('guidedInterviewOn' === tabOn) {
      this.onGuidedInterviewOn();
    }
  }

  onServiceGuideOn() {
    this.serviceGuideOn = true;
    this.guidedInterviewOn = false;
    this.historyService.setTabOn('serviceGuideOn');
  }

  onGuidedInterviewOn() {
    this.serviceGuideOn = false;
    this.guidedInterviewOn = true;
    this.historyService.setTabOn('guidedInterviewOn');
  }

  setWorkflow(workflowId: number) {
    this.workflowId = workflowId;
  }

  onWorkflow() {
    const url = '/workflows/' + this.workflowId;
    console.log(url);
    // window.open(url, '_blank');
    this.router.navigate([url]);
  }

  registerToggle() {
    this.registerMode = true;
  }
  cancelRegisterMode(registerMode: boolean) {
    this.registerMode = registerMode;
  }

  ngOnDestroy() {
    if (this.subCategory) {
      this.subCategory.unsubscribe();
    }
  }

  scrollToAnnouncements() {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: '.announcements-container'
    });
  }

  scrollToSelfHelpCatalog() {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: '.self-help-catalog'
    });
  }

  
}
