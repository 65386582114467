import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageHistory } from 'src/app/models/pagehistory';
import { HistoryService } from 'src/app/services/history.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {
  history: PageHistory[] = [];
  private historySub: Subscription;
  private workflowId = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private historyService: HistoryService) {
      this.workflowId = parseInt(this.route.snapshot.paramMap.get('id'));
  }

  ngOnInit() {
    this.historySub = this.historyService.historyChanged.subscribe((pageHistory: PageHistory[]) => {
      this.history = pageHistory.filter(i => i.workflowId == this.workflowId);
      // console.log(this.history);
      // console.log(this.workflowId);
    });
  }

  goBack(pageHistory: PageHistory) {
    if (pageHistory.pageNumber === -1) {
      this.router.navigate(['workflows/categories']);
    } else {
      this.historyService.removePageFromHistory(pageHistory);
    }
  }

  getTitle(historyPage: PageHistory) {
    if (historyPage.otherText.toLowerCase() !== '') {
      return historyPage.otherText;
    } else {
      return historyPage.questionText;
    }
  }

  ngOnDestroy() {
    if (this.historySub) {
      this.historySub.unsubscribe();
    }
  }
}
