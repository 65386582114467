import { Injectable } from "@angular/core";
import { Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';
import { AlertifyService } from '../services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Message } from '../models/message';
import { AuthService } from '../services/auth.service';

@Injectable()
export class MessagesResolver implements Resolve<Message[]>{
pageNumber = 1;
pageSize = 5;
messageContainer = 'Unread';

    constructor(private userService:UserService,
        private router:Router, private alertifyService:AlertifyService,private authService:AuthService) {}

    resolve(route: import("@angular/router").ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Message[]>  {
       return this.userService.getMessages(this.authService.decodedToken.nameid,this.pageNumber,this.pageSize,this.messageContainer).pipe(
           catchError(error => {
            this.alertifyService.error('Problem retrieving messages');
            this.router.navigate['/home'];
            this.userService.getUser
            return of(null);
           })
        );
        }
    
}