import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContentService } from '../services/content.service';
import { LangService } from '../services/lang.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  private proxyURL = location.origin + '/api';
  textsObject: SelfHelpResources.TextsObject;
  langSubscription: Subscription;
  curLangCode = '';
  versionInfo = '';

  constructor(
    private contentService: ContentService,
    private langService: LangService,
    private http: HttpClient) {
    this.langSubscription = this.langService.onLangChange().subscribe(() => {
      this.contentService.getTexts(this.langService.getLangCode()).subscribe(result => {
        this.textsObject = result[0];
        // console.log(this.textsObject.footerBody);
      });
    });
    this.http.get(this.proxyURL + '/Environment/getVersionInfo')
      .subscribe((r: any) => {
        // console.log(r.Value);
        this.versionInfo = r.value;
      });
  }

  ngOnInit() {
    this.contentService.getTexts().subscribe(result => {
      this.textsObject = result[0];
    });
  }

  ngOnDestroy() {
    this.langSubscription.unsubscribe();
  }
}
