import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class AlertifyService {

  constructor() {}

  /**
     * Confirm dialog object
     *
     *	alertify.confirm(message);
     *	alertify.confirm(message, onok);
     *	alertify.confirm(message, onok, oncancel);
     *	alertify.confirm(title, message, onok, oncancel);
     */
  confirm(message: string,okCallback: () => any,title:string = 'Confirm'){
   
    alertify.confirm(title,message,function(e){
    if(e){
        okCallback();
    }
    else{}
    },
    (e)=>{})
  }
/**
     * Prompt dialog object
     *
     * invoked by:
     *	alertify.prompt(message);
     *	alertify.prompt(message, value);
     *	alertify.prompt(message, value, onok);
     *	alertify.prompt(message, value, onok, oncancel);
     *	alertify.prompt(title, message, value, onok, oncancel);
     */
  prompt(message: string,okCallback: (value:string) =>  any,title:string ='Prompt'){
 
    alertify.prompt(title,message,'',(e,value)=>{
      if(e){
       okCallback(value);
    }
    else{}
    },
    ()=>{}
    ) 
  }
  success(message: string){

    alertify.success(message); 
  }
  error(message: string){

    alertify.error(message,10); 
  }
  /**
    * Alert dialog definition
    *
    * invoked by:
    *	alertify.alert(message);
    *	alertify.alert(title, message);
    *	alertify.alert(message, onok);
    *	alertify.alert(title, message, onok);
     */
  alert(message: string,title:string ='Alert'){
   
    alertify.alert(title,message); 
  }
}
