import { Locale } from './locale';

export class Tag {
    selfhelpTagsId: number;
    workflowId: number;
    masterTextId: number;   // @TODO: remove this
    tagCode: string;
    text: string;
    taggedResourcesCount: number;
    date_created: Date;
    date_modified: Date;

    sortOrder: number;
}
