import { Component, OnInit, OnDestroy } from '@angular/core';
import { ContentService } from '../services/content.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-service-catalog',
  templateUrl: './service-catalog.component.html',
  styleUrls: ['./service-catalog.component.scss']
})
export class ServiceCatalogComponent implements OnInit, OnDestroy {
  content: any;
  categoryTopics: SelfHelpResources.CategoryTopic[] = [];
  currentCategory: SelfHelpResources.Category;
  topics: SelfHelpResources.Topic[] = [];
  categoryId: any;
  private routerSub: Subscription;
  subCategoryTopics: Subscription;
  subCategory: Subscription;
  textsObject: SelfHelpResources.TextsObject;

  constructor(private contentService: ContentService, private route: ActivatedRoute) {
    this.contentService.getTexts().subscribe(result => {
      this.textsObject = result[0];
     // console.log(this.textsObject);
    });
  }

  ngOnInit() {
    this.contentService.getTopics().subscribe(result => {
      this.topics = result.Topic.sort((a, b) => (a.rankTopic > b.rankTopic) ? 1 : -1);
      this.routerSub = this.route.params.subscribe(params => {
        if (params['categoryId']) {
          this.categoryId = params['categoryId'];
          this.subCategory = this.contentService.getCategories().subscribe(result1 => {
            this.currentCategory = result1.Category.find(x => x.categoryId === this.categoryId);
          });
          this.subCategoryTopics = this.contentService.getCategoriesTopics().subscribe(result2 => {
            this.categoryTopics = result2.CategoryTopic.filter(x => x.categoryId === this.categoryId);
            this.categoryTopics = this.categoryTopics.sort((a, b) => {
              const x = this.topics.find(o => o.topicId === a.topicId);
              const y = this.topics.find(o => o.topicId === b.topicId);
              return (x.rankTopic > y.rankTopic) ? 1 : -1;
            });
            // console.log(this.categoryTopics);
          });
        }
      });
    });
  }

  getTopic(topicId): SelfHelpResources.Topic {
    const result = this.topics.find(x => x.topicId === topicId);
    return result;
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    if (this.subCategoryTopics) {
      this.subCategoryTopics.unsubscribe();
    }
    if (this.subCategory) {
      this.subCategory.unsubscribe();
    }
  }
}
