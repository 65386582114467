import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { User } from 'src/app/models/user';
import { EnvironmentService, EnvVar } from 'src/app/services/environment.service';
import { LangService } from 'src/app/services/lang.service';

import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class xRow {
  id: number;
  action: string;
  new_value: string;
}
export class xSheet {
  name: string;
  rows: xRow[];
}
export class xTable {
  table_name: string;
  sheets: xSheet[];
}


@Injectable({
  providedIn: 'root'
})
export class ImportService {
  constructor(private http: HttpClient) {
  }

  preview(file: File): Observable<xTable[]> {
    const url = `${environment.services_base_url}/api/import/preview`;
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<xTable[]>(url, formData).pipe(catchError(this.onError));
  }

  import(file: File): Observable<xTable[]> {
    const url = `${environment.services_base_url}/api/import/import`;
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<xTable[]>(url, formData).pipe(catchError(this.onError));
  }

  onError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }
}
