import { Injectable } from '@angular/core';
import { PageHistory } from '../models/pagehistory';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  private tabOn = 'serviceGuideOn';
  public historySubject = new BehaviorSubject<PageHistory[]>([]);
  private pageSubject = new BehaviorSubject<PageHistory[]>([]);

  public pageHistory: PageHistory[] = [];

  historyChanged = this.historySubject.asObservable();
  pageChanged = this.pageSubject.asObservable();

  constructor() { }

  getTabOn() {
    return this.tabOn;
  }

  setTabOn(tabOn) {
    this.tabOn = tabOn;
  }

  addPageToHistory(page: PageHistory) {
    const  pageToAdd = this.pageHistory.find(p => p.pageId === page.pageId);
    if (pageToAdd) {
      this.pageHistory.splice(this.pageHistory.indexOf(pageToAdd), 1);
      this.pageHistory.push(page);
    } else {
      this.pageHistory.push(page);
    }
    this.historySubject.next(this.pageHistory);
    // console.log('service: '); 
    // console.log(this.pageHistory);
  }

  removePageFromHistory(page: PageHistory) {
    page.isRemoved = true;
    const removedPage = this.pageHistory.find(p => p.pageId === page.pageId);
    if (removedPage) {
      const pageIndex = this.pageHistory.indexOf(removedPage);
      this.pageHistory[pageIndex] = page;
      this.pageSubject.next(this.pageHistory.slice());
      this.pageHistory = this.pageHistory.splice(0, pageIndex);
      this.historySubject.next(this.pageHistory.slice());
    }
  }

  restoreHistory(page: PageHistory) {
    page.isRemoved = true;
    const removedPage = this.pageHistory.find(p => p.pageId === page.pageId);
    if (removedPage) {
      const pageIndex = this.pageHistory.indexOf(removedPage);
      this.pageHistory[pageIndex] = page;
      this.pageSubject.next(this.pageHistory.slice());
      this.pageHistory = this.pageHistory.splice(0, pageIndex);
      this.historySubject.next(this.pageHistory.slice());
    }
  }

  clearHistory() {
    this.pageHistory = [];
    this.historySubject.next(this.pageHistory);
  }
}
