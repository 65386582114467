import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditorComponent } from './admin/editor/editor.component';
import { CategoriesComponent } from './categories/categories.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './guards/auth.guard';
import { MemberDetailResolver } from './resolvers/member-detail.resolver';
import { MemberEditComponent } from './members/member-edit/member-edit.component';
import { MemberDetailComponent } from './members/member-detail/member-detail.component';
import { AdminPanelComponent } from './admin/admin-panel/admin-panel.component';
import { MemberEditResolver } from './resolvers/member-edit.resolver';
import { PreventUnsavedChangesGuard } from './guards/prevent-unsaved-changes.guard';
import { MemberDashboardComponent } from './members/member-dashboard/member-dashboard.component';
import { ResultsComponent } from './workflows/results/results.component';
import { TagsComponent } from './admin/tags/tags.component';
import { SurveyComponent } from './survey.component';
import { ResetPasswordComponent } from './members/reset-password/reset-password.component';
import { EmailConfirmComponent } from './members/email-confirm/email-confirm.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { ServiceCatalogComponent } from './service-catalog/service-catalog.component';
import { DetailsComponent } from './details/details.component';
import { UserProfileComponent } from './userprofile/userprofile.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { ImportComponent } from './import/import.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'userprofile', component: UserProfileComponent },
  { path: 'favorites', component: FavoritesComponent },
  { path: 'import', component: ImportComponent /*, data: { roles: ['Admin', 'Moderator'] } */ },
  {
    // This technique of creating placeholder route with '' empty path helps to protect
    // Multiple routes with just a single GUARD
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      { path: 'members', component: MemberDashboardComponent },
      { path: 'members/:id', component: MemberDetailComponent, resolve: { user: MemberDetailResolver } },
      {
        path: 'member/edit', component: MemberEditComponent,
        resolve: { user: MemberEditResolver }, canDeactivate: [PreventUnsavedChangesGuard],
      },
      { path: 'admin', component: AdminPanelComponent, data: { roles: ['Admin', 'Moderator'] } },
     
      { path: 'admin/tags', component: TagsComponent, data: { roles: ['Admin', 'Moderator'] } },
      { path: 'workflows/categories', component: CategoriesComponent }
    ]
  },
  { path: 'categories', component: CategoriesComponent },
  { path: 'search-results/:categoryId/:topicId', component: SearchResultsComponent },
  { path: 'service-catalog/:categoryId', component: ServiceCatalogComponent },
  { path: 'details/:resourceId', component: DetailsComponent },
  { path: 'workflows/:id', component: SurveyComponent },
  { path: 'workflows/results/:id/:name', component: ResultsComponent },
  { path: 'password/reset', component: ResetPasswordComponent },
  { path: 'email/confirm', component: EmailConfirmComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
