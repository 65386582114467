import { Component, Input, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import * as Survey from 'survey-angular';
import * as widgets from 'surveyjs-widgets';
import 'inputmask/dist/inputmask/phone-codes/phone.js';
import { HistoryService } from './services/history.service';
import { PageHistory } from 'src/app/models/pagehistory';
import { Subscription } from 'rxjs';
import { ResourceService } from './services/resource.service';
import { take } from 'rxjs/operators';
import { WorkflowService } from './services/workflow.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Workflow } from './models/workflow';
import { WorkflowSession } from './models/workflowSession';
import { LangService } from './services/lang.service';

widgets.icheck(Survey);
widgets.select2(Survey);
widgets.inputmask(Survey);
widgets.jquerybarrating(Survey);
widgets.jqueryuidatepicker(Survey);
widgets.nouislider(Survey);
widgets.select2tagbox(Survey);
widgets.signaturepad(Survey);
widgets.sortablejs(Survey);
widgets.ckeditor(Survey);
widgets.autocomplete(Survey);
widgets.bootstrapslider(Survey);
widgets.prettycheckbox(Survey);
// widgets.emotionsratings(Survey);

Survey.JsonObject.metaData.addProperty('questionbase', 'popupdescription:text');
Survey.JsonObject.metaData.addProperty('page', 'popupdescription:text');
Survey.StylesManager.applyTheme('default');

// @Component({
//   selector: 'survey',
//   template: `<div class='survey-container contentcontainer codecontainer'>
// <div id='surveyElement'></div><button (click)='savePDF()'>Save PDF</button></div>`
// })
@Component({
  selector: 'survey',
  templateUrl: './survey.component.html'
})
export class SurveyComponent implements OnInit, OnDestroy {
  // @Input()
  json: any = {};

  @Output()
  submitSurvey = new EventEmitter<any>();

  workflowId: number;
  workflow: Workflow;
  result: any;
  surveyModel: Survey.Model;
  resultsParams: any = {};
  private historySub: Subscription;
  private langSubscription: Subscription;
  private sessionResultsId = 'RESULTS';
  private sessionWorkflowStateId = 'WORKFLOWSTATE';
  private sessionHistoryStateId = 'HISTORYSTATE';
  private currentPage = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private historyService: HistoryService,
    private resourcesService: ResourceService,
    private langService: LangService,
    private workflowService: WorkflowService
  ) {
  }

  ngOnInit() {
    this.historyService.clearHistory();
    this.langService.enableLang();
    this.route.params.subscribe(params => {
      // console.log(params);
      if (params['id']) {
        this.workflowId = params['id'];
      }
      if (this.workflowId > 0) {
        // console.log(this.langService.getLangCode());
        this.workflowService.getWorkflow(this.workflowId, this.langService.getLangCode(), false).subscribe(workflow => {
          this.workflow = workflow;
          this.bindWorkflow(workflow);
          this.initSurvey();
        });
      }
    });
  }

  // savePDF() {
  //   console.log(this.json);
  //   console.log(this.result);
  // }

  bindWorkflow(workflow: Workflow) {
    this.workflow = workflow;
    this.json = JSON.parse(this.workflow.json);
  }

  onComplete(result) {
    this.result = result.data;
    // this.saveWorkflowState(result);

    const pageHistory = this.historyService.pageHistory;
    this.saveHistoryState(pageHistory);

    const tags = [];
    pageHistory.forEach(el => {
      const q = el.questionText.substring(0, el.questionText.indexOf('['));
      const a = el.questionText.substring(el.questionText.indexOf('['));
      tags.push(q + '{' + el.pageId + '}' + a);
    });
    this.resultsParams.TagNames = tags;
    this.historyService.clearHistory();
    result.render();

    this.resourcesService.getResults(tags, this.workflow.selfhelpWorkflowsId, this.langService.getLangCode()).subscribe(res => {
      this.submitSurvey.emit(res);
      this.router.navigate(['/workflows/results', this.workflow.selfhelpWorkflowsId, this.workflow.displayName.toLocaleLowerCase()]);
    });

  }

  currentPageChanged(result, options) {
    const test = result;
  }

  currentPageChanging(result, options) {
  }

  onValueChanged(result, options) {
    this.langService.disableLang();
    if (options.value != null && options.value !== '') {
      const pageHistory = new PageHistory();
      let otherText = '';
      pageHistory.workflowId = this.workflowId;
      pageHistory.questionId = options.question.name;
      pageHistory.questionText = options.question.fullTitle + `[${options.question.displayValue}]`;
      otherText = (options.question.otherText.toLowerCase() === 'other (describe)') ? '' : options.question.otherText;
      otherText = otherText.replace('{{answer}}', `[${options.question.displayValue}]`);
      pageHistory.otherText = otherText;
      pageHistory.pageId = options.question.page.name;
      pageHistory.pageNumber = options.question.page.visibleIndex;
      this.historyService.addPageToHistory(pageHistory);
    }
  }

  initSurvey() {
    this.surveyModel = new Survey.Model(this.json);
    this.surveyModel.onAfterRenderQuestion.add((survey, options) => {
      if (!options.question.popupdescription) { return; }
      // Add a button;
      const btn = document.createElement('button');
      btn.className = 'btn btn-info btn-xs';
      btn.innerHTML = 'More Info';
      btn.onclick = () => {
        // showDescription(question);
        alert(options.question.popupdescription);
      };
      const header = options.htmlElement.querySelector('h5');
      const span = document.createElement('span');
      span.innerHTML = '  ';
      header.appendChild(span);
      header.appendChild(btn);
    });
    this.loadWorkflowState(this.surveyModel);
    // this.loadHistoryState();
    this.surveyModel.onCurrentPageChanging.add((result, options) => {
      this.currentPageChanging(result, options);
    });
    this.surveyModel.onValueChanged.add((result, options) => {
      this.onValueChanged(result, options);
    });
    this.surveyModel.onCurrentPageChanged.add((result, options) => {
      this.currentPageChanged(result, options);
    });
    this.surveyModel.onComplete.add((result, options) => {
      this.onComplete(result);
    });
    Survey.SurveyNG.render('surveyElement', {
      model: this.surveyModel, showQuestionNumbers: 'off',
      showTitle: false,
      showNavigationButtons: false,
      goNextPageAutomatic: true,
      showCompletedPage: false,
      currentPageNo: this.currentPage
    });
    this.historySub = this.historyService.pageChanged.subscribe((pageHistory: PageHistory[]) => {
      if (pageHistory != null) {
        const removedPage = pageHistory.find(p => p.isRemoved === true);
        if (removedPage) {
          let filteredPageHistory = [];
          const pageIndex = pageHistory.indexOf(removedPage);
          this.surveyModel.currentPageNo = pageIndex;
          if (pageIndex === 0) {
            filteredPageHistory = pageHistory.splice(pageIndex);
          } else {
            filteredPageHistory = pageHistory.splice(pageIndex, pageHistory.length - 1);
          }
          for (const page of filteredPageHistory) {
            this.surveyModel.setValue(page.questionId, null);
          }
        }
      }
    });
  }

  onHome() {
    this.historyService.clearHistory();
    this.router.navigate(['/']);
  }

  saveWorkflowState(surveyModel) {
    const workflowSession = {
      pages: surveyModel.pages,
      visiblePages: surveyModel.visiblePages,
      currentPageNo: surveyModel.currentPageNo,
      data: surveyModel.data
    };
    // Here should be optionally the code to save the data into your database
    sessionStorage.setItem(this.sessionWorkflowStateId, JSON.stringify(workflowSession));
  }

  loadWorkflowState(surveyModel) {
    // Here should be the code to load the data from your database
    const workflowStateStr = sessionStorage.getItem(this.sessionWorkflowStateId) || '';
    let workflowSession = new WorkflowSession();
    if (workflowStateStr) {
      workflowSession = JSON.parse(workflowStateStr);
    }
    this.currentPage = workflowSession.currentPageNo;
    // Set the loaded data into the survey.
    if (workflowSession.data) {
      surveyModel.data = workflowSession.data;
    }
  }

  saveHistoryState(pageHistory: PageHistory[]) {
    sessionStorage.setItem(this.sessionHistoryStateId, JSON.stringify(pageHistory));
  }

  loadHistoryState() {
    // Here should be the code to load the data from your database
    const historyStateStr = sessionStorage.getItem(this.sessionHistoryStateId) || '';
    if (historyStateStr) {
      const pageHistory = JSON.parse(historyStateStr);
      const length = pageHistory.length;
      this.historyService.pageHistory = pageHistory;
      this.historyService.restoreHistory(pageHistory[length - 1]);
      sessionStorage.removeItem(this.sessionHistoryStateId);
    }
  }

  // =====================================================================================================
  ngOnDestroy() {
    if (this.historySub) {
      this.historySub.unsubscribe();
    }
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }

}
