import { Injectable } from "@angular/core";
import { User } from '../models/user';
import { Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';
import { AlertifyService } from '../services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class MemberDetailResolver implements Resolve<User>{


    constructor(private userService:UserService,
        private router:Router, private alertifyService:AlertifyService) {}

    resolve(route: import("@angular/router").ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User>  {
       return this.userService.getUser(route.params['id']).pipe(
           catchError(error => {
            this.alertifyService.error('Problem retrieving data');
            this.router.navigate['/members'];
            
            return of(null);
           })
        );
        }
    
}