import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { environment } from '../../environments/environment';
import { ContentService } from '../services/content.service';
import { AlertifyService } from '../services/alertify.service';

declare var ga;

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  favEnabled = '/assets/images/favorites-icon-enabled.png';
  favDisabled = '/assets/images/favorites-icon-disabled.png';
  topicId: '';
  categoryId = '';
  content: any;
  resourceIds: string[] = [];

  categories: SelfHelpResources.Category[] = [];
  resources: SelfHelpResources.Resource[] = [];
  topicResources: SelfHelpResources.TopicResource[] = [];
  types: SelfHelpResources.Type[] = [];
  topics: SelfHelpResources.Topic[] = [];
  workshops: any;

  servicesBaseUrl = '';
  userAuthenticated = false;
  userAdGuid = '';
  favorites = [];

  subCategory: Subscription;
  subTopicResources: Subscription;
  subTypes: Subscription;
  subTopics: Subscription;
  subRouter: Subscription;

  public textsObject: SelfHelpResources.TextsObject;

  constructor(private contentService: ContentService,
              private route: ActivatedRoute,
              private http: HttpClient,
              private alertifyService: AlertifyService) {
      this.contentService.getTexts().subscribe(result => {
        this.textsObject = result[0];
        // console.log(this.textsObject);
      });
  }

  ngOnInit() {
    this.subTypes = this.contentService.getTypes().subscribe(result => {
      this.types = result.Type.sort((a, b) => (a.rankType > b.rankType) ? 1 : -1);
       console.log(this.types);
    });

    this.subTopics = this.contentService.getTopics().subscribe(result => {
      this.topics = result.Topic.sort((a, b) => (a.rankTopic > b.rankTopic) ? 1 : -1);
    });

    this.subCategory = this.contentService.getCategories().subscribe(result => {
      this.categories = result.Category;
    });

    this.subRouter = this.route.params.subscribe(params => {
      if (params['categoryId']) {
        this.categoryId = params['categoryId'];
      }

      if (params['topicId']) {
        this.topicId = params['topicId'];
      }
    });

    this.subTopicResources = this.contentService.getResourcesTopics().subscribe(result => {
      this.topicResources = result.TopicResource.filter(x => x.topicId === this.topicId);
      this.resourceIds = [];
      this.topicResources.forEach((kv) => {
        this.resourceIds.push(kv.resourceId);
      });
      // console.log(this.topicResources);
      this.contentService.getResources().subscribe(res => {
        this.resources = res.Resource.filter(f => this.resourceIds.includes(f.resourceId));
        this.resources = this.resources.sort((a, b) => (this.getRankResource(a.resourceId) > this.getRankResource(b.resourceId)) ? 1 : -1);
        console.log(this.resources);
        this.initUserFavorites();
      });
    });
  }

  initUserFavorites() {
    this.servicesBaseUrl = window.location.origin + '/api';
    let url = this.servicesBaseUrl + '/Authentication/getUserInfo';
    this.http.get(url).subscribe((resp1: any) => {
      this.userAuthenticated = Boolean(resp1.isAuthenticated);
      if (this.userAuthenticated) {
        url = this.servicesBaseUrl + '/Authentication/getUserProfile';
        this.http.get(url).subscribe((resp2: any) => {
          this.userAdGuid = resp2.guid;
          url = this.servicesBaseUrl + '/Authentication/getUserFavorites/' + this.userAdGuid;
          this.http.get(url).subscribe((resp3: any) => {
            this.favorites = resp3;
            this.resources.forEach(r => {
              let found = false;
              for (const fv of this.favorites) {
                if (fv.selfhelpResourcesId === r.selfhelpResourcesId) {
                  found = true;
                  break;
                }
              }
              r.img = (found) ? this.favEnabled : this.favDisabled;
            });
          });
        });
      }
    });
  }

  onFavorite(resource: SelfHelpResources.Resource) {
    resource.img = (this.favDisabled === resource.img) ? this.favEnabled : this.favDisabled;
    if (this.favEnabled === resource.img) {
      const url = this.servicesBaseUrl + '/Authentication/addUserFavorite/' + this.userAdGuid + '/' + resource.selfhelpResourcesId;
      this.http.post(url, {}).subscribe((resp: any) => {
        if (200 === resp.statusCode) {
          this.alertifyService.success('User favorite add succeeded.');
        } else {
          this.alertifyService.error('User favorite add failed.');
        }
        this.initUserFavorites();
      });
    } else {
      const fv = this.favorites.find(f => f.selfhelpResourcesId === resource.selfhelpResourcesId && f.userAdGuid === this.userAdGuid);
      const url = this.servicesBaseUrl + '/Authentication/removeUserFavorite/' + fv.selfhelpFavoritesId;
      this.http.post(url, {}).subscribe((resp: any) => {
        if (200 === resp.statusCode) {
          this.alertifyService.success('User favorite remove succeeded.');
        } else {
          this.alertifyService.error('User favorite remove failed.');
        }
        this.initUserFavorites();
      });
    }
  }

  private getRankResource(resourceId) {
    const rankResource = this.topicResources.find(r => r.resourceId === resourceId).rankResource;
    // console.log(rankResource);
    return rankResource;
  }

  getCategory(categoryId): SelfHelpResources.Category {
    return this.categories.find(x => x.categoryId === categoryId);
  }

  getTopic(topicId): SelfHelpResources.Topic {
    return this.topics.find(x => x.topicId === topicId);
  }

  getResourceType(typeId): SelfHelpResources.Type {
    return this.types.find(x => x.typeId === typeId);
  }

  filterRows(resources, typeId) {
    return resources.filter(x => x.typeId === typeId);
  }

  isExist(typeId) {
    if (this.resources.length < 0) {
      return false;
    } else {
      return this.resources.filter(x => x.typeId === typeId).length > 0;
    }
  }

  goToLink(resource: SelfHelpResources.Resource) {
    const url: string = this.route.snapshot.url.join('/');
    if (!environment.production) {
      console.log(url);
    }
    const parts = url.split('/');
    const gaEvent = this.contentService.getGAevent(parts[1], parts[2], resource.resourceId);
    ga('send', 'event', gaEvent.categoryName, gaEvent.action, gaEvent.label);
    if (!environment.production) {
      console.log(`ga('send', 'event', ${JSON.stringify(gaEvent)})`);
    }
    window.open(resource.url, '_blank');
  }

  ngOnDestroy() {
    if (this.subRouter) {
      this.subRouter.unsubscribe();
    }
    if (this.subTypes) {
      this.subTypes.unsubscribe();
    }
    if (this.subTopicResources) {
      this.subTopicResources.unsubscribe();
    }
    if (this.subTopics) {
      this.subTopics.unsubscribe();
    }
    if (this.subCategory) {
      this.subCategory.unsubscribe();
    }
  }
}
