import { Component, OnDestroy, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { Tag } from 'src/app/models/tag';
import { EditorService } from 'src/app/services/editor.service';
import { TagService } from 'src/app/services/tag.service';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, map, take } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { TaggedResourceService } from 'src/app/services/taggedresource.service';
import { TaggedResource } from 'src/app/models/taggedResource';
import { ResourcesModalComponent } from '../resources-modal/resources-modal.component';
import { TagsModalComponent } from '../tags-modal/tags-modal.component';
import { AlertifyService } from 'src/app/services/alertify.service';
import { Workflow } from 'src/app/models/workflow';
import { getLocaleDateFormat } from '@angular/common';
import { WorkflowService } from 'src/app/services/workflow.service';
import { LangService } from 'src/app/services/lang.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit, OnDestroy {
  @Output()
  update = new EventEmitter<number>();
  @Output()
  generate = new EventEmitter<number>();

  workflow: Workflow;
  editing = {};
  resourceParams: any = {};
  taggedResourceParams: any = {};
  taggedResources: TaggedResource[];
  tagRows: Tag[];
  tagResult: Tag[];
  resourceRows: SelfHelpResources.Resource[];
  resourceResult: SelfHelpResources.Resource[];
  ColumnMode;
  bsModalRef: BsModalRef;
  workflowChangedSub: Subscription;
  workflowDeletedSub: Subscription;
  workflowAddedSub: Subscription;
  resourceRefreshSub: Subscription;
  tagRefreshSub: Subscription;

  @ViewChild('searchTag', { static: false })
  searchTag: any;

  constructor(
    private alertifyService: AlertifyService,
    private resourceService: ResourceService,
    private taggedResourceService: TaggedResourceService,
    private editorService: EditorService,
    private tagService: TagService,
    private workflowService: WorkflowService,
    private langService: LangService,
    private modalService: BsModalService,
    private spinnerService: SpinnerService
  ) { }

  ngOnInit() {
    this.workflowChangedSub = this.editorService.workflowChanged.subscribe(workflow => {
      this.workflow = workflow;
      this.getResourcesAndTags();
    });

    this.workflowDeletedSub = this.workflowService.onWorkflowDeleted.subscribe(workflow => {
      this.workflow = workflow;
      this.getResourcesAndTags();
    });

    this.workflowAddedSub = this.workflowService.onWorkflowAdded.subscribe(workflow => {
      this.workflow = workflow;
      this.getResourcesAndTags();
    });

    this.tagRefreshSub = this.tagService.onRefresh.subscribe(res => {
      this.onRefreshTags();
    });

    this.resourceRefreshSub = this.resourceService.onRefresh.subscribe(res => {
      this.onRefreshResources();
    });
  }

  getResourcesAndTags() {
    if (null != this.workflow) {
      this.resourceService.getResources(this.langService.getLangCode()).subscribe(data => {
        this.resourceResult = data;
        this.resourceRows = data;
      });

      this.tagService.getTags(this.workflow.selfhelpWorkflowsId, this.langService.getLangCode(), false).subscribe(data => {
        this.tagResult = data;
        this.tagRows = this.tagResult;
        this.sortTags();
      });
    }
  }

  updateFilter(val: any) {
    const searchCharArray = val.split('');
    if (!searchCharArray || searchCharArray.length < 1) {
      this.onRefreshTags();
    } else {
      const value = val.toString().toLowerCase().trim();
      if (this.tagRows.length > 0) {
        // get the key names of each column in the dataset
        const keys = Object.keys(this.tagRows[0]);
        if (keys.length > 0) {
          // get the amount of columns in the table
          const count = keys.length;
          // assign filtered matches to the active datatable

          this.tagRows = this.tagResult.filter(item => {
            // iterate through each row's column data
            for (let i = 0; i < count; i++) {
              // check for a match
              if (
                (item[keys[i]] &&
                  item[keys[i]]
                    .toString()
                    .toLowerCase()
                    .indexOf(value) !== -1) ||
                !value
              ) {
                // found match, return true to add to result set
                return true;
              }
            }
          });
        }
      }
    }
  }

  showResourcesModal(tagId: any) {
    this.spinnerService.show();
    this.taggedResourceService.getTaggedResources(this.workflow.selfhelpWorkflowsId, tagId).
      subscribe((res: TaggedResource[]) => {
        this.taggedResources = res;
        const initialState = {
          workflowId: this.workflow.selfhelpWorkflowsId,
          tag: this.tagRows.find(t => t.selfhelpTagsId === tagId),
          resourceRows: this.resourceRows,
          taggedResources: this.taggedResources,
          title: 'Modal with component'
        };

        this.bsModalRef = this.modalService.show(TagsModalComponent, { initialState });
        this.bsModalRef.setClass('modal-lg');
        this.spinnerService.hide();
      });
  }

  onGenerateTags() {
    this.generate.emit(this.workflow.selfhelpWorkflowsId);
  }

  onUpdateTags() {
    this.update.emit(this.workflow.selfhelpWorkflowsId);
  }
  
  sortTags() {
    // console.log(this.tagRows);
    this.workflowService.getWorkflow(this.workflow.selfhelpWorkflowsId, 'en', true).subscribe(workflow => {
      this.workflow = workflow;
      const choices = this.tagService.etlTags(this.workflow.json, 'gen');
      // console.log(choices);
      choices.forEach((c, index) => {
        const tagIndex = this.tagRows.findIndex(t => t.text === c);
        this.tagRows[tagIndex].sortOrder = index;
      });
      this.tagRows = this.tagRows.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1 );
      this.tagRows = [...this.tagRows];
      // console.log(this.tagRows);
    });
  }

  onRefreshTags() {
    this.spinnerService.show();
    this.tagService.getTags(this.workflow.selfhelpWorkflowsId, this.langService.getLangCode(), false).subscribe(data => {
      this.tagResult = data;
      this.tagRows = data;
      this.tagRows = [...this.tagRows];
      this.sortTags();
      this.searchTag.nativeElement.value = '';
      this.spinnerService.hide();
    });
  }

  onRefreshResources() {
    this.spinnerService.show();
    this.resourceService.getResources(this.langService.getLangCode()).subscribe(data => {
      this.resourceResult = data;
      this.resourceRows = data;
      this.resourceRows = [...this.resourceRows];
      this.searchTag.nativeElement.value = '';
      this.spinnerService.hide();
    });
  }

  onClearTaggedResources() {
    this.alertifyService.confirm('Are you sure you want to Reset all tags?', () => {
      this.taggedResourceService.clearTaggedResources(this.workflow.selfhelpWorkflowsId).subscribe(data => {
        this.tagService.refresh();
        this.resourceService.refresh();
      });
    }, 'Confirm');
  }

  ngOnDestroy() {
    if (this.workflowChangedSub) {
      this.workflowChangedSub.unsubscribe();
    }
    if (this.workflowDeletedSub) {
      this.workflowDeletedSub.unsubscribe();
    }
    if (this.workflowAddedSub) {
      this.workflowAddedSub.unsubscribe();
    }
    if (this.resourceRefreshSub) {
      this.resourceRefreshSub.unsubscribe();
    }
    if (this.tagRefreshSub) {
      this.tagRefreshSub.unsubscribe();
    }
  }
}
