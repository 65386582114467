import {  OnInit, Output, Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { User } from '../../models/user';



@Component({
  selector: 'app-roles-modal',
  templateUrl: './roles-modal.component.html',
  styleUrls: ['./roles-modal.component.scss']
})
export class RolesModalComponent implements OnInit {
  user: User;
  roles: any[];
  
  @Output() 
  updateSelectedRoles = new EventEmitter()

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {

  }

  updateRoles(){
      this.updateSelectedRoles.emit(this.roles);
      this.bsModalRef.hide();
  }

}

