import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { take, map } from 'rxjs/operators';
import { JSONPath } from 'jsonpath-plus/dist/index-es.js';
import { Tag } from '../models/tag';
import { PaginatedResult } from '../models/pagination';
import { TagsParams } from '../models/tagsParams';
import { Workflow } from '../models/workflow';
import { Locale } from '../models/locale';
import { EnvironmentService, EnvVar } from 'src/app/services/environment.service';
import { LangService } from 'src/app/services/lang.service';
import { add } from 'ngx-bootstrap/chronos';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  private envVars: EnvVar = null;
  private refreshSubject = new Subject();
  onRefresh = this.refreshSubject.asObservable();

  constructor(private http: HttpClient,
              private langService: LangService,
              private envService: EnvironmentService) {
    this.envVars = this.envService.getEnvVars();
  }

  createTags(workflowId: number, tags: string[]): Observable<Tag[]> {
    return this.http.post<Tag[]>(this.envVars.services_base_url + '/Tags/createTags/' + workflowId, tags);
  }

  getTags(workflowId: number, langCode = 'en', addCode = false): Observable<Tag[]> {
    return this.http.get<Tag[]>(this.envVars.services_base_url + '/Tags/getTags/' + workflowId + '/' + langCode + '/' + addCode);
  }

  updateTags(workflowId: number, tags: Tag[]) {
    return this.http.put<Tag[]>(this.envVars.services_base_url + '/Tags/updateTags/' + workflowId, tags);
  }

  getTagsCount(workflowId: number): Observable<number> {
    return this.http.get<number>(this.envVars.services_base_url + '/Tags/getTagsCount/' + workflowId);
  }

  refresh() {
    this.refreshSubject.next();
  }

  getTagNames(json: string): string[] {
    const choices: Array<string> = [];
    const tags: Tag[] = [];
    const result = JSONPath({ path: '$.pages..elements', json: JSON.parse(json) });

    result.array.forEach(r => {
      let questionTitle = '';
      const questionArr = r;
      if (questionArr.length > 0) {
        const question = questionArr[0];
        questionTitle = question.title;
        const choicesArr = question.choices;
        choicesArr.array.forEach(c => {
          if (choicesArr.length > 0) {
            const choice = (c instanceof (Object)) ? c.text : c;
            if (choices.indexOf(questionTitle + '[' + choice + ']') === -1) {
              choices.push(questionTitle + '[' + choice + ']');
            }
          }
        });
      }
    });

    return choices;
  }

  cleanse(text: string) {
    const length = text.indexOf('<');
    if (-1 !== length) {
      text = text.substring(0, length);
    }
    return text;
  }

  getTagCode(text: string) {
    const length = text.indexOf('<');
    if (-1 !== length) {
      text = text.substring(length + 1, text.length - 1);
    }
    return text;
  }

  etlTags(json: string, mode: string) {
    const pages: [] = JSONPath({ path: '$.[pages].*', json: JSON.parse(json) });
    const finalChoices: Array<string> = [];

    pages.forEach((p: any) => {
      const page = p.name;
      const elements: Array<object> = p.elements;
      elements.forEach((e: any) => {
        const question: any = e;
        let tagCode = '';
        let questionTitle = (question.title) ? question.title : question.name;
        switch (mode) {
          default:
          case 'gen':
            questionTitle = this.cleanse(questionTitle);
            break;
          case 'update':
            tagCode = this.getTagCode(questionTitle);
            questionTitle = this.cleanse(questionTitle);
            break;
        }
        const choices: Array<string> = e.choices;
        const tagCodeOrig = tagCode;
        choices.forEach((c: any) => {
          tagCode = tagCodeOrig;
          let choice = (c instanceof (Object)) ? c.text : c;
          switch (mode) {
            default:
            case 'gen':
              choice = this.cleanse(choice);
              break;
            case 'update':
              tagCode += '[' + this.getTagCode(choice) + ']';
              choice = this.cleanse(choice);
              break;
          }
          let text = questionTitle + '{' + p.name + '}' + '[' + choice + ']';
          text = ('update' === mode) ? tagCode + text : text;
          if (-1 === finalChoices.indexOf(text)) {
            finalChoices.push(text);
          }
        });
      });
    });

    return finalChoices;
  }

}
