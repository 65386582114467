import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Photo } from 'src/app/models/photo';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { AlertifyService } from 'src/app/services/alertify.service';
import { EnvironmentService, EnvVar } from 'src/app/services/environment.service';

@Component({
  selector: 'app-photo-editor',
  templateUrl: './photo-editor.component.html',
  styleUrls: ['./photo-editor.component.scss']
})
export class PhotoEditorComponent implements OnInit {
  @Input()
  photos: Photo[]
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean = false;
  currentMainPhoto: Photo;

  @Output()
  getMemberPhotoChange = new EventEmitter<string>();

  private env_vars: EnvVar = null;
  constructor(private authService: AuthService,
    private userService: UserService,
    private alertifyService: AlertifyService,
    private _env_service: EnvironmentService) {
    this.env_vars = this._env_service.getEnvVars();
  }

  ngOnInit() {
    this.initializeUploader();
  }
  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  initializeUploader() {
    this.uploader = new FileUploader({
      url: this.env_vars.services_base_url + '/users/' + this.authService.decodedToken.nameid + '/photos',
      authToken: 'Bearer ' + localStorage.getItem('token'),
      isHTML5: true,
      allowedFileType: ['image'],
      removeAfterUpload: true,
      autoUpload: false,
      maxFileSize: 10 * 1024 * 1024
    })


    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; }
    this.uploader.onSuccessItem = (item, response, status, headers) => {
      const res: Photo = JSON.parse(response);
      const photo = {
        id: res.id,
        url: res.url,
        dateAdded: res.dateAdded,
        description: res.description,
        isMain: res.isMain,
        isApproved: res.isApproved
      };
      this.photos.push(photo);

      if (photo.isMain) {
        this.authService.changeMemberPhoto(photo.url);
        this.authService.currentUser.photoUrl = photo.url;

        localStorage.setItem('user', JSON.stringify(this.authService.currentUser));
      }
    }
  }

  setMainPhoto(photo: Photo) {
    this.userService.setMainPhoto(this.authService.decodedToken.nameid, photo.id).subscribe(() => {
      //Current Main Photo  Flag To false 
      this.currentMainPhoto = this.photos.filter(p => p.isMain === true)[0];
      this.currentMainPhoto.isMain = false;
      //New Main Photo Flag to true
      photo.isMain = true;

      this.authService.changeMemberPhoto(photo.url);
      this.authService.currentUser.photoUrl = photo.url;

      localStorage.setItem('user', JSON.stringify(this.authService.currentUser));

    }, error => {
      this.alertifyService.error(error);
    });
  }

  deletePhoto(id: number) {
    this.alertifyService.confirm('Are you sure you want to delete this photo?', () => {
      this.userService.deletePhoto(this.authService.decodedToken.nameid, id).subscribe(() => {
        this.photos.splice(this.photos.findIndex(p => p.id === id), 1);
        this.alertifyService.success('Photo deleted');
      }, error => {
        this.alertifyService.error(error);
      });
    });
  }
}
