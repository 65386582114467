import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AlertifyService } from 'src/app/services/alertify.service';
import { User } from 'src/app/models/user';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-email-confirm',
  templateUrl: './email-confirm.component.html',
  styleUrls: ['./email-confirm.component.scss']
})
export class EmailConfirmComponent implements OnInit {
  private userId: number;
  resetToken: string;
  user: User;
  
  constructor(private route: ActivatedRoute, private authService: AuthService, private alertifyService: AlertifyService) { }

  ngOnInit() {
   
    this.route.queryParams.subscribe(params => {

      if (params['token'])
        this.resetToken = params['token'];

      if (params['uid'])
        this.userId = params['uid'];
    });
    this.confirmEmail();
  }

  confirmEmail() {

      if (this.userId && this.resetToken) {
        this.user = Object.assign({});
        this.user.id = this.userId;
        this.user.resetToken = this.resetToken;

        this.authService.confirmEmail(this.user).pipe(take(1)).subscribe(result => {
          this.alertifyService.success("Email Has Been Confirmed");
  
        });
      }
    
  }

}
