import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from 'src/app/models/user';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { take } from 'rxjs/operators';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  user: User;
  private userId: number;
  resetToken: string;
  passwordResetForm: FormGroup;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private authService: AuthService, private alertifyService: AlertifyService) { }


  ngOnInit() {
    this.InitPasswordResetForm();

    this.route.queryParams.subscribe(params => {

      if (params['token'])
        this.resetToken = params['token'];

      if (params['uid'])
        this.userId = params['uid'];
    });
  }
  resetPassword($event) {

    if (this.passwordResetForm.valid) {
      //Object.assign clones property values and maps to new object
      if (this.userId && this.resetToken) {
        this.user = Object.assign({}, this.passwordResetForm.value);
        this.user.id = this.userId;
        this.user.resetToken = this.resetToken;
        this.user.password = this.passwordResetForm.get('password').value;
        this.user.newPassword = this.passwordResetForm.get('password').value;
        this.authService.resetPassword(this.user).pipe(take(1)).subscribe(result => {
          this.alertifyService.success("Password Has Been Reset");
          this.passwordResetForm.reset();
        });
      }
    }
  }

  InitPasswordResetForm() {
    this.passwordResetForm = this.fb.group({
      password: ['', [Validators.required,
      Validators.minLength(4),
      Validators.maxLength(8)]],
      confirmPassword: ['', [Validators.required]]
    },
      { validator: this.passwordMatchValidator })
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('password').value === g.get('confirmPassword').value ?
      null : { 'mismatch': true };
  }
}
