import { Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { EditorService } from 'src/app/services/editor.service';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, map, take } from 'rxjs/operators';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap';
import { ResourcesModalComponent } from '../resources-modal/resources-modal.component';
import { Tag } from 'src/app/models/tag';
import { TagService } from 'src/app/services/tag.service';
import { PaginatedResult } from 'src/app/models/pagination';
import { TaggedResource } from 'src/app/models/taggedResource';
import { TaggedResourceService } from 'src/app/services/taggedresource.service';
import { AlertifyService } from 'src/app/services/alertify.service';
import { Workflow } from 'src/app/models/workflow';
import { ResourceEditModalComponent } from '../resource-edit-modal/resource-edit-modal.component';
import { ResourceType } from 'src/app/models/resourceType';
import { WorkflowService } from 'src/app/services/workflow.service';
import { LangService } from 'src/app/services/lang.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit, OnDestroy {
  workflow: Workflow;
  resourceTypes: ResourceType[] = [];
  resourceResult: SelfHelpResources.Resource[] = [];
  resourceRows: SelfHelpResources.Resource[] = [];
  taggedResources: TaggedResource[] = [];
  tagRows: Tag[] = [];

  workflowChangedSub: Subscription;
  workflowDeletedSub: Subscription;
  workflowAddedSub: Subscription;
  resourceRefreshSub: Subscription;
  tagRefreshSub: Subscription;

  editing = {};
  bsModalRef: BsModalRef;
  ColumnMode;

  @ViewChild('searchResource', { static: false })
  searchResource: any;

  constructor(
    private workflowService: WorkflowService,
    private alertifyService: AlertifyService,
    private resourceService: ResourceService,
    private taggedResourceService: TaggedResourceService,
    private editorService: EditorService,
    private tagService: TagService,
    private langService: LangService,
    private modalService: BsModalService,
    private spinnerService: SpinnerService) {
  }

  ngOnInit() {
    this.resourceService.getResourceTypes(this.langService.getLangCode()).subscribe((data: any) => {
      this.resourceTypes = data.Type;
    });
    this.workflowChangedSub = this.editorService.workflowChanged.subscribe(workflow => {
      this.workflow = workflow;
      this.getData();
    });

    this.workflowDeletedSub = this.workflowService.onWorkflowDeleted.subscribe(workflow => {
      this.workflow = workflow;
      this.getData();
    });

    this.workflowAddedSub = this.workflowService.onWorkflowAdded.subscribe(workflow => {
      this.workflow = workflow;
      this.getData();
    });

    this.resourceRefreshSub = this.resourceService.onRefresh.subscribe(res => {
      this.onRefreshResources();
    });

    // this.tagRefreshSub = this.tagService.onRefresh.subscribe(res => {
    //   this.onRefreshTags();
    // });
  }

  updateFilter(val: any) {
    const searchCharArray = val.split('');

    if (!searchCharArray || searchCharArray.length < 1) {
      this.onRefreshResources();
    } else {
      const value = val.toString().toLowerCase().trim();
      if (this.resourceRows.length > 0) {
        // get the key names of each column in the dataset
        const keys = Object.keys(this.resourceRows[0]);
        if (keys.length > 0) {
          // get the amount of columns in the table
          const count = keys.length;
          // assign filtered matches to the active datatable
          this.resourceRows = this.resourceResult.filter(item => {
            // iterate through each row's column data
            for (let i = 0; i < count; i++) {
              // check for a match
              if (
                (item[keys[i]] &&
                  item[keys[i]]
                    .toString()
                    .toLowerCase()
                    .indexOf(value) !== -1) ||
                !value
              ) {
                // found match, return true to add to result set
                return true;
              }
            }
          });
        }
      }
    }
  }

  showTagsModal(resourceId: any) {
    this.spinnerService.show();
    this.taggedResourceService.getResourcesTagged(this.workflow.selfhelpWorkflowsId, this.langService.getLangCode())
      .subscribe((res: SelfHelpResources.Resource[]) => {
        // @TODO: this.taggedResources = res;
        this.tagService.getTags(this.workflow.selfhelpWorkflowsId, this.langService.getLangCode(), false).subscribe(data => {
          this.tagRows = data;
          this.tagRows = [...this.tagRows];

          const initialState = {
            workflowId: this.workflow.selfhelpWorkflowsId,
            resource: this.resourceRows.find(r => r.selfhelpResourcesId === resourceId),
            tags: this.tagRows,
            taggedResources: this.taggedResources,
            title: 'Modal with component',
          };

          this.bsModalRef = this.modalService.show(ResourcesModalComponent, { initialState });
          this.bsModalRef.setClass('modal-lg');
          this.spinnerService.hide();
        });
      });
  }

  deleteResourceTags(resourceId) {
    // this.resourceService.deleteResource(resourceId).pipe(take(1)).subscribe(result => {
    //   this.alertifyService.success('Deleted');
    //   this.getData();
    // });
  }

  // onRefreshTags() {
  //   this.tagService.getTags(this.workflow.selfhelpWorkflowsId, this.langService.getLangCode(), false).subscribe(data => {
  //     this.tagRows = data;
  //     this.tagRows = [...this.tagRows];
  //   });
  // }

  onRefreshResources() {
    this.spinnerService.show();
    this.taggedResourceService.getResourcesTagged(this.workflow.selfhelpWorkflowsId, this.langService.getLangCode()).subscribe(data => {
      this.resourceResult = data;
      this.resourceRows = data;
      this.resourceRows = [...this.resourceRows];
      this.searchResource.nativeElement.value = '';
      this.spinnerService.hide();
    });
  }

  onEdit(id) {
    const selectedWorkflow: Workflow[] = [this.workflow];
    const thisResource: SelfHelpResources.Resource = this.resourceRows.find((r: any) => r.id === id);
    const initialState = {
      title: 'Edit Resource',
      isEditMode: true,
      resource: thisResource,
      workflows: selectedWorkflow,
      resourceTypes: this.resourceTypes
    };

    this.bsModalRef = this.modalService.show(ResourceEditModalComponent, { initialState });
    this.bsModalRef.setClass('modal-lg');
  }

  getData() {
    if (null != this.workflow) {
      this.taggedResourceService.getResourcesTagged(this.workflow.selfhelpWorkflowsId, this.langService.getLangCode()).subscribe(data => {
        this.resourceResult = data;
        this.resourceRows = data;
      });

      // this.tagService.getTags(this.workflow.selfhelpWorkflowsId, this.langService.getLangCode(), false).subscribe(data => {
      //   this.tagRows = data;
      // });
    }
  }

  getResourceType(typeId: string) {
    const rt = this.resourceTypes.find(t => t.typeId === typeId);
    return (null !== rt) ? rt.name : 'typeId: ' + typeId + ' not found!';
  }

  ngOnDestroy() {
    if (this.workflowChangedSub) {
      this.workflowChangedSub.unsubscribe();
    }
    if (this.workflowDeletedSub) {
      this.workflowDeletedSub.unsubscribe();
    }
    if (this.workflowAddedSub) {
      this.workflowAddedSub.unsubscribe();
    }
    if (this.resourceRefreshSub) {
      this.resourceRefreshSub.unsubscribe();
    }
    if (this.tagRefreshSub) {
      this.tagRefreshSub.unsubscribe();
    }
  }
}
