import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { environment } from '../environments/environment';
import { AuthService } from './services/auth.service';
import { ContentService } from './services/content.service';
import { EnvironmentService, EnvVar } from './services/environment.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from './models/user';
import { filter } from 'rxjs/operators';

declare var gtag;
declare var ga;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  jwtHelper = new JwtHelperService();
  private envVars: EnvVar = null;
  constructor(private router: Router,
              private authService: AuthService,
              private contentService: ContentService,
              private envService: EnvironmentService) {
    this.envVars = this.envService.getEnvVars();
    if ('' === this.envVars.ga_tracking_id) {
      this.envService.initEnvVars().subscribe((e: EnvVar) => {
        this.envVars = e;
        ga('create', this.envVars.ga_tracking_id, 'auto');
      });
    } else {
      ga('create', this.envVars.ga_tracking_id, 'auto');
    }
    // gtag('config', ga_tracking_id);

    const navEndEvents = router.events.pipe(filter(event => event instanceof NavigationEnd));
    navEndEvents.subscribe((event: NavigationEnd) => {
      if (!environment.production) {
        // console.log(event.urlAfterRedirects);
      }
      // @TODO: revisit this....
      // const parts = event.urlAfterRedirects.split('/');
      // if ('details' === parts[1]) {
      //   const gaEvent = this.contentService.getGAevent(parts[2], parts[3], parts[4]);
      //   ga('send', 'event', gaEvent.categoryName, gaEvent.action, gaEvent.label);
      //   if (!environment.production) {
      //     console.log(`ga('send', 'event', ${JSON.stringify(gaEvent)})`);
      //   }
      // }
    });
  }

  ngOnInit() {
    const token = localStorage.getItem('token');
    const user: User = JSON.parse(localStorage.getItem('user'));

    if (token) {
      this.authService.decodedToken = this.jwtHelper.decodeToken(token);
      this.authService.userName = this.authService.decodedToken.unique_name;
    }
    if (user) {
      this.authService.currentUser = user;
      this.authService.changeMemberPhoto(user.photoUrl);
    }
  }
}
