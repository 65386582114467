import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { Message } from '../models/message';
import { PaginatedResult } from '../models/pagination';
import { map } from 'rxjs/operators';
import { EnvironmentService, EnvVar } from 'src/app/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private env_vars: EnvVar = null;

  constructor(private http: HttpClient,
    private _env_service: EnvironmentService) {
    this.env_vars = this._env_service.getEnvVars();
  }

  getUsers(page?, itemsPerPage?, userParams?, likesParam?): Observable<PaginatedResult<User[]>> {
    const paginatedResults: PaginatedResult<User[]> = new PaginatedResult<User[]>();

    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    if (userParams != null) {
      params = params.append('minAge', userParams.minAge);
      params = params.append('maxAge', userParams.maxAge);
      params = params.append('gender', userParams.gender);
      params = params.append('orderBy', userParams.orderBy);
    }

    //HTTP GET RETURNS USERS[] SO WE USE RXJS MAP FUNCTION TO RETURN A DIFFERENT TYPE OF OBJECT
    //BY FIRST MAPPING IT TO NEW PaginatedResult<User[]>();
    return this.http.get<User[]>(this.env_vars.services_base_url + '/users', { observe: 'response', params }).pipe(
      map(response => {
        paginatedResults.result = response.body;

        if (response.headers.get('Pagination') != null)
          paginatedResults.pagination = JSON.parse(response.headers.get('Pagination'))

        return paginatedResults;
      })
    );
  }

  getUser(id: number): Observable<User> {
    return this.http.get<User>(this.env_vars.services_base_url + '/users/' + id);
  }

  updateUser(id: number, user: User) {
    return this.http.put<User>(this.env_vars.services_base_url + '/users/' + id, user);
  }

  setMainPhoto(userId: number, Id: number) {
    return this.http.post(this.env_vars.services_base_url + '/users/' + userId + "/photos/" + Id + "/setMain", {});
  }

  deletePhoto(userId: number, id: number) {
    return this.http.delete(this.env_vars.services_base_url + "/users/" + userId + "/photos/" + id);
  }

  getMessages(id: number, page?, itemsPerPage?, messageContainer?) {
    const paginatedResult: PaginatedResult<Message[]> = new PaginatedResult<Message[]>();

    let params = new HttpParams();
    params = params.append('MessageContainer', messageContainer);

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }

    return this.http.get<Message[]>(this.env_vars.services_base_url + "/users/" + id + "/messages", { observe: "response", params }).pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })

    );
  }

  getMesssageThread(id: number, recipientId: number) {
    return this.http.get<Message[]>(this.env_vars.services_base_url + "/users/" + id + "/messages/thread/" + recipientId);
  }

  sendMessage(id: number, message: Message) {
    return this.http.post(this.env_vars.services_base_url + "/users/" + id + "/messages/", message);
  }

  deleteMessage(id: number, userId: number) {
    return this.http.post(this.env_vars.services_base_url + '/users/' + userId + '/messages/' + id, {});
  }

  markAsRead(userId: number, messageId: number) {
    this.http.post(this.env_vars.services_base_url + "/users/" + userId + "/messages/" + messageId + "/read", {}).subscribe();
  }
}
