import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ImportService, xTable, xSheet, xRow } from 'src/app/services/import.service';
import { TreeTableData, TreeTableRow, TreeTableDataConfig, TreeTableHeaderObject } from 'angular-tree-table';
import { CombineLatestOperator } from 'rxjs/internal/observable/combineLatest';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {
  tableConfig = {
    showExpandArrows: true,
    showExpandAllArrows: false,
    columnVisibility: false,
    columnVisibilityDropDown: false,
    showTableHeaders: false,
    visibleColumnFiltersVisibility: false,
    showPageLengthDropdown: false,
    commonSearch: false
  };
  rowConfig = {
    showExpandArrows: true,
    showExpandAllArrows: false,
    columnVisibility: false,
    columnVisibilityDropDown: false,
    showTableHeaders: true,
    visibleColumnFiltersVisibility: false,
    showPageLengthDropdown: true,
    commonSearch: true
  };
  tableData: TreeTableData = null;

  file: File | null = null;
  tables: xTable[] = [];
  constructor(private http: HttpClient,
              private spinnerService: SpinnerService,
              private importService: ImportService) {
    // this.tableData = new TreeTableData(this.tableConfig);
    // console.log(this.tableData);
  }

  ngOnInit() {
  }


  onFile(files: FileList) {
    this.file = files.item(0);
  }

  onPreview() {
    this.spinnerService.show();
    this.importService.preview(this.file).subscribe(res => {
      this.tables = res;
      console.log(this.tables);
      this.genTableData();
      this.spinnerService.hide();
    });
  }

  onImport() {
    this.spinnerService.show();
    this.importService.import(this.file).subscribe(res => {
      this.tables = res;
      console.log(this.tables);
      this.genTableData();
      this.spinnerService.hide();
    });
  }

  genTableData() {
    const data = [];
    let tid = 1;
    this.tables.forEach(t => {
      let table_name = `${t.table_name}: `;
      t.sheets.forEach(s => {
        table_name += `${s.name}(${s.rows.length}) `;
      });
      const row = new TreeTableRow(tid + '', { table_name: table_name }, true, null);
      row.expandable = true;
      row.children = this.genSheetData(t.sheets);
      tid++;

      data.push(row);
    });

    this.tableData = new TreeTableData(this.tableConfig);
    this.tableData.data = data;
    this.tableData.headers = this.genTableHeaders();
    console.log(this.tableData);
  }

  genSheetData(sheets: xSheet[]): TreeTableData {
    const data = [];
    let sid = 1;
    sheets.forEach(s => {
      const lang_code = `${s.name}(${s.rows.length})`;
      const row = new TreeTableRow(sid + '', { lang_code: lang_code }, true, null);
      row.expandable = true;
      row.children = this.genRowData(s.rows);
      sid++;

      data.push(row);
    });

    const sheetData = new TreeTableData(this.tableConfig);
    sheetData.data = data;
    sheetData.headers = this.genSeetHeaders();
    return sheetData;
  }

  genRowData(rows: xRow[]): TreeTableData {
    const data = [];
    let rid = 1;
    rows.forEach(r => {
      if ('nochange' !== r.action) {
        const row = new TreeTableRow(rid + '', {
          id: r.id,
          action: r.action,
          new_value: r.new_value
        }, true, null);
        row.expandable = false;
        rid++;

        data.push(row);
      }
    });

    const rowData = new TreeTableData(this.rowConfig);
    rowData.data = data;
    rowData.headers = this.genRowHeaders();
    return rowData;
  }

  genTableHeaders(): TreeTableHeaderObject[] {
    const h: TreeTableHeaderObject[] = [];
    h.push(new TreeTableHeaderObject('Table Name', 'table_name', null, true));
    return h;
  }

  genSeetHeaders(): TreeTableHeaderObject[] {
    const h: TreeTableHeaderObject[] = [];
    h.push(new TreeTableHeaderObject('Lang Code', 'lang_code', null, true));
    return h;
  }

  genRowHeaders(): TreeTableHeaderObject[] {
    const h: TreeTableHeaderObject[] = [];
    h.push(new TreeTableHeaderObject('ID', 'id', null, true));
    h.push(new TreeTableHeaderObject('Action', 'action', null, true));
    h.push(new TreeTableHeaderObject('New Value', 'new_value', null, true));
    return h;
  }
}
