import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Workflow } from 'src/app/models/workflow';
import { BsModalRef } from 'ngx-bootstrap';
import { WorkflowService } from 'src/app/services/workflow.service';
import { AlertifyService } from 'src/app/services/alertify.service';

@Component({
  selector: 'app-workflows-modal',
  templateUrl: './workflows-modal.component.html',
  styleUrls: ['./workflows-modal.component.scss']
})
export class WorkflowsModalComponent implements OnInit, OnDestroy {
  title: string;
  workflow: Workflow = new Workflow();
  mode = 'create';
  constJson = `{
    "pages": [
       {
        "name": "page1"
       }
      ]
     }`;

  constructor(
    public bsModalRef: BsModalRef,
    private workflowService: WorkflowService,
    private alertifyService: AlertifyService) {
      this.workflow.name = '';
      this.workflow.displayName = '';
      this.workflow.description = '';
  }

  ngOnInit() {
    this.mode = this.workflowService.getMode();
    if ('create' === this.mode) {
      this.workflow = new Workflow();
    } else {
      this.workflowService.getWorkflow(this.workflowService.getUpateWorkflowId(), 'en', false).subscribe(workflow => {
        this.workflow = workflow;
      });
    }
  }

  submit() {
    if ('create' === this.mode) {
      if (undefined === this.workflow.json || null === this.workflow.json || '' === this.workflow.json) {
        this.workflow.json = this.constJson;
      }
      this.workflowService.createWorkflow(this.workflow).subscribe((res: any) => {
        if (200 === res.code) {
          this.workflowService.workflowAdded(res.msg as Workflow);
          this.alertifyService.success('Created');
        } else {
          this.alertifyService.error(res.msg);
        }
      });
    } else {
      this.workflowService.updateWorkflow(this.workflow).subscribe((res: any) => {
        if (200 === res.code) {
          this.alertifyService.success('updated');
        } else {
          this.alertifyService.error(res.msg);
        }
      });
    }
    this.bsModalRef.hide();
  }

  filterChars(event) {
    let key;
    key = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((key > 64 && key < 91) ||
      (key > 96 && key < 123) ||
      key === 45 ||
      key === 95 ||
      key === 8 ||
      key === 32 ||
      (key >= 48 && key <= 57));
  }

  onKeyDown(event, workflowForm) {
    let key;
    key = event.charCode;  //
    if (event.keyCode === 13) {
      if (workflowForm.valid) {
        this.submit();
      }
    } else {
      event.stopPropagation();
    }
  }

  ngOnDestroy(): void {
    if (this.workflow) {
      this.workflow = null;
    }
  }

}
