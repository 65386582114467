import { Injectable } from '@angular/core';
import {CanActivate, UrlTree, Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AlertifyService } from '../services/alertify.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private authService:AuthService,private router:Router,private alertifyService:AlertifyService, private activatedRoute:ActivatedRoute){}
  canActivate(next:ActivatedRouteSnapshot): boolean{
    
    const roles = next.firstChild.data['roles'] as Array<string>;
    if(roles){
      const match = this.authService.roleMatch(roles);
      if(match){
      return true;
      }
      else{
      this.router.navigate(['members']);
      }
    }


    if(this.authService.loggedIn()){
      return true;
    }

    if(this.authService.loggedIn()){
    return true;  
  }
  else{
    
    this.alertifyService.error('Access Denied');
    this.router.navigate(["/Home"])

    return false;
  }
  }
    
    
    
}
