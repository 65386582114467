import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';
import { ContentService } from '../services/content.service';
import { Subscription } from 'rxjs';

declare var ga;

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnDestroy {
  resourceId: string;
  // categoryId: string;
  // topicId: string;
  resource: SelfHelpResources.Resource;
  // topics: SelfHelpResources.Topic[] = [];
  resourcesTypes: SelfHelpResources.TypeResource[] = [];
  types: SelfHelpResources.Type[] = [];
  subTypes: Subscription;
  subResourceTypes: Subscription;
  // categories: SelfHelpResources.Category[] = [];
  // subCategory: Subscription;
  // subTopics: Subscription;
  textsObject: SelfHelpResources.TextsObject;
  private routerSub: Subscription;
  constructor(
    private contentService: ContentService,
    private location: Location,
    private route: ActivatedRoute) {
    this.contentService.getTexts().subscribe(result => {
      this.textsObject = result[0];
      if (!this.textsObject.goback) {
        this.textsObject.goback = this.getGoback(this.textsObject.langCode);
      }
      // console.log(this.textsObject)
    });
  }

  getGoback(lang_code: string): string {
    let text = '';
    switch(lang_code.toLocaleLowerCase()) {
      case 'en': text = 'Go Back'; break;
      case 'es': text = 'Autoayuda'; break;
      case 'hy': text = 'Ինքնօգնություն'; break;
      case 'zh': text = '自助服务'; break;
      case 'kr': text = '자력 구제'; break;
      case 'vi': text = 'Tự Trợ Giúp'; break;
    }
    return text;
  }

  ngOnInit() {
    this.routerSub = this.route.params.subscribe(params => {
      // if (params['categoryId']) {
      //   this.categoryId = params['categoryId'];
      // }

      // if (params['topicId']) {
      //   this.topicId = params['topicId'];
      // }

      if (params['resourceId']) {
        this.resourceId = params['resourceId'];
      }

      this.subTypes = this.contentService.getTypes().subscribe(result => {
        this.types = result.Type;
      });

      // this.subTopics = this.contentService.getTopics().subscribe(result => {
      //   this.topics = result.Topic.sort((a, b) => (a.rankTopic > b.rankTopic) ? 1 : -1);
      // });

      // this.subCategory = this.contentService.getCategories().subscribe(result => {
      //   this.categories = result.Category;
      // });

      this.contentService.getResources().subscribe(result => {
        this.resource = result.Resource.find(x => x.resourceId === this.resourceId);
        const ts = { resourceId: this.resource.resourceId, typeId: this.resource.typeId};
        this.resourcesTypes.push(ts);
      });
    });
  }

  goBack() {
    this.location.back();
  }

  goToLink(resource: SelfHelpResources.Resource) {
    const url: string = this.route.snapshot.url.join('/');
    if (!environment.production) {
      console.log(url);
    }
    // @TODO: revisit this....
    // const parts = url.split('/');
    // const gaeEvent = this.contentService.getGAevent(parts[1], parts[2], resource.resourceId);
    // gaeEvent.label += ' | CRM';
    // ga('send', 'event', gaeEvent.categoryName, gaeEvent.action, gaeEvent.label);
    // if (!environment.production) {
    //   console.log(`ga('send', 'event', ${JSON.stringify(gaeEvent)})`);
    // }
    window.open(resource.url, '_blank');
  }

  // getTopic(topicId): SelfHelpResources.Topic {
  //   return this.topics.find(x => x.topicId === topicId);
  // }

  getType(typeId): SelfHelpResources.Type {
    const result = this.types.find(x => x.typeId === typeId);
    return result;
  }

  // getCategory(categoryId): SelfHelpResources.Category {
  //   return this.categories.find(x => x.categoryId === categoryId);
  // }

  ngOnDestroy() {
    // if (this.subCategory) {
    //   this.subCategory.unsubscribe();
    // }
    // if (this.subTopics) {
    //   this.subTopics.unsubscribe();
    // }
    if (this.subTypes) {
      this.subTypes.unsubscribe();
    }
    if (this.subResourceTypes) {
      this.subResourceTypes.unsubscribe();
    }
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }
}
