import { Component, OnInit, ViewChild, HostListener, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user';
import { AlertifyService } from 'src/app/services/alertify.service';
import { NgForm } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { BookmarkedResource } from 'src/app/models/bookmarkedResource';
import { BookmarkedResourceService } from 'src/app/services/bookmarkedResource.service';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { State } from 'src/app/models/state';
import { LookupService } from 'src/app/services/lookup.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/public_api';

@Component({
  selector: 'app-member-edit',
  templateUrl: './member-edit.component.html',
  styleUrls: ['./member-edit.component.scss']
})
export class MemberEditComponent implements OnInit,AfterViewInit,OnDestroy {
  ColumnMode;
  editing = {};
  bookmarkedResourceResult: BookmarkedResource[];
  bookmarkedResourceRows: BookmarkedResource[];
  user: User;
  photoUrl:string;
  @ViewChild('editForm',{static:false})
  editForm: NgForm;
  @ViewChild('searchResource', { static: false })
  searchResource: any;
  bookmarkedResourceSub:Subscription;
  statesSub:Subscription;
  states:State[];
  @ViewChild('dateOfBirth',{ static: false }) dateOfBirth: ElementRef;
  //adding Partial keyword allows to override only preffered setttings
  //makes rest optional
  bsConfig: Partial<BsDatepickerConfig>

  //Host listener attribute allows to listen and react to browser events:
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.editForm.dirty) {
      //returning true by default returns "Leaving?" Prompt
      $event.returnValue = true;
    }
  }

  constructor(private lookupService:LookupService,private bookmarkedResourceService:BookmarkedResourceService,private route: ActivatedRoute,private userService:UserService, private authService:AuthService, private alertifyService: AlertifyService) { }

  ngOnInit() {

   

    this.route.data.subscribe((data) => {
      this.user = data["user"];

      this.bsConfig = {
        containerClass: 'theme-red',
        dateInputFormat: 'MM/DD/YYYY'
      }

      this.statesSub = this.lookupService.getStates().subscribe(states=>{
        this.states = states;
      })


      let bookmarkedResourceParams ={
        userId:this.user.id
      }

      this.bookmarkedResourceSub =  this.bookmarkedResourceService.getBookmarkedResources(null,null,bookmarkedResourceParams).pipe(take(1)).subscribe(data => {
        this.bookmarkedResourceResult = data.result;
        this.bookmarkedResourceRows = data.result;
      });

    })
    this.authService.currentPhotoUrl.subscribe(photoUrl=>{
      this.photoUrl = photoUrl;
    });



  }

  ngAfterViewInit() {
    //this.dateOfBirth.value = new Date();
  }

  updateUser() {
    this.userService.updateUser(this.authService.decodedToken.nameid,this.user).subscribe((user)=>{
      this.alertifyService.success('Profile updated successfully');
      this.user = user;
      this.editForm.reset(this.user);

    },error => this.alertifyService.error(error));

    console.log(this.user);
  }

  updateFilter(val: any) {
    let searchCharArray = val.split('');

    if (!searchCharArray || searchCharArray.length  < 1 ) {
      this.onRefreshResources();
    }
    else {
      const value = val.toString().toLowerCase().trim();

      if (this.bookmarkedResourceRows.length > 0) {

        // get the key names of each column in the dataset
        const keys = Object.keys(this.bookmarkedResourceRows[0]);
        if (keys.length > 0) {
          // get the amount of columns in the table
          const count = keys.length;
          // assign filtered matches to the active datatable

          this.bookmarkedResourceRows = this.bookmarkedResourceResult.filter(item => {
            // iterate through each row's column data
            for (let i = 0; i < count; i++) {
              // check for a match
              if (
                (item[keys[i]] && item[keys[i]].toString().toLowerCase().indexOf(value) !== -1) || (item.workflowName.toString().toLowerCase().indexOf(value) !== -1) || !value) {
                // found match, return true to add to result set
                return true;
              }
            }
          });
        }
      }
    }
  }

  deleteBookmarkedResource(bookmarkedResourceId) {

    this.bookmarkedResourceService.deleteBookmarkedResource(bookmarkedResourceId).pipe(take(1)).subscribe(result => {

      this.alertifyService.success("Deleted");

      this.getData();

    });
  }

  getData() {

    let bookmarkedResourceParams ={
      userId:this.user.id
    }
    this.bookmarkedResourceService.getBookmarkedResources(null,null,bookmarkedResourceParams).pipe(take(1)).subscribe(data => {
      this.bookmarkedResourceResult = data.result;
      this.bookmarkedResourceRows = data.result;
    });
  }

  onRefreshResources() {

  let bookmarkedResourceParams ={
    userId:this.user.id
  }

    this.bookmarkedResourceService.getBookmarkedResources(null,null,bookmarkedResourceParams).pipe(take(1)).subscribe(data => {
      this.bookmarkedResourceResult = data.result;
      this.bookmarkedResourceRows = data.result;
      this.bookmarkedResourceRows = [...this.bookmarkedResourceRows];
      this.searchResource.nativeElement.value = '';
    })
  }

  getResourceType(type: string) {
    switch (type.toLowerCase()) {
      case "pdf":
        return "/assets/images/resources/pdf-icon.png";
      case "hyperlink":
        return "/assets/images/resources/link-icon.png";
      default:
        return "";
    }

  }

  updateMainPhoto(photoUrl){
    this.user.photoUrl = photoUrl;
  }

  
  ngOnDestroy() {
    if (this.statesSub) {
      this.statesSub.unsubscribe();
    }
    if (this.bookmarkedResourceSub) {
      this.bookmarkedResourceSub.unsubscribe();
    }
  }
}
