import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { take, switchMap, filter } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { AlertifyService } from '../services/alertify.service';
import { ContentService } from '../services/content.service';
import { LangService } from '../services/lang.service';
import { EmailMessage } from '../models/emailMessage';
import { stringify } from 'querystring';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})

export class NavComponent implements OnDestroy {

  textsObject: SelfHelpResources.TextsObject;
  servicesBaseUrl = '';
  model: any = {};
  displayName: any;
  photoUrl: string;
  isNavHidden = false;
  isUserNameValid = false;
  routerSub: Subscription;
  langSubscription: Subscription;
  isAdmin = false;

  profileDisplayName = '';
  signInUrl = '';
  signOffUrl = '';
  userAuthenticated = false;
  document: any;
  pageScrollService: any;

  constructor(
    private authService: AuthService,
    private alertifyService: AlertifyService,
    private router: Router,
    private http: HttpClient,
  ) {
    this.servicesBaseUrl = window.location.origin + '/api';
    this.signInUrl = this.servicesBaseUrl + '/Authentication/Login';
    this.signOffUrl = this.servicesBaseUrl + '/Authentication/LogOff';
  }

  ngOnInit() {
    this.authService.currentPhotoUrl.subscribe(photoUrl => {
      this.photoUrl = photoUrl;
    });
    const url = this.servicesBaseUrl + '/Authentication/getUserInfo';
    this.http.get(url).subscribe((resp: any) => {
      this.userAuthenticated = Boolean(resp.isAuthenticated);
      this.profileDisplayName = resp.displayName;
    });

    const navEndEvents = this.router.events.pipe(filter(event => event instanceof NavigationEnd));
    navEndEvents.subscribe((event: NavigationEnd) => {
      if (!environment.production) {
        // console.log(event.urlAfterRedirects);
      }
      if (event.urlAfterRedirects.toLowerCase().indexOf('?admin=') > - 1) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    });

    this.routerSub = this.router.events.subscribe(val => {
      if (window.location.href.toLowerCase().indexOf('?token=') > -1) {
        this.isNavHidden = true;
      } else {
        this.isNavHidden = false;
      }
    });
  }

  login() {
    this.authService.login(this.model).subscribe(
      next => {
        this.alertifyService.success('Logged In Successfully.');
        console.log('Logged in successfully.');
        // console.log(this.model);
      },
      error => {
        this.alertifyService.error(error);
        console.log(error);
      }, () => {
        this.model.username = '';
        this.model.password = '';
        this.router.navigate(['/admin']);
      });
  }

  loggedIn() {
    const isLoggedIn = this.authService.loggedIn();

    if (isLoggedIn) {
      this.displayName = this.authService.userName;
    }
    return isLoggedIn;
  }

  logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.authService.decodedToken = null;
    this.authService.currentUser = null;
    this.alertifyService.alert('You Have Been Logged Out.');

    console.log('logged out');
    this.router.navigate(['/home']);
  }

  onForgotPassword(userName) {
    if (userName.length >= 4) {
      this.authService.verifyByUsername(userName).pipe(take(1)).subscribe((user: User) => {

        return this.authService.sendResetPasswordEmail(user).pipe(take(1)).subscribe(result => {

          this.alertifyService.success('Email Has Been Sent.');
        });
      });
    }
  }

  verifyUsername($event) {
    return true;
    const userName = $event.target.value;
    if (userName.length >= 4) {
      this.authService.verifyByUsername(userName).pipe(take(1)).subscribe((user: User) => {
        if (user) {
          this.isUserNameValid = (userName === user.userName) ? true : false;
        } else {
          this.isUserNameValid = false;
        }
      });
    } else {
      this.isUserNameValid = false;
    }
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }

    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }

  /* Toggle between showing and hiding the navigation menu links when the user clicks on the hamburger menu / bar icon */
  myMobileMenu() {
    var x = document.getElementById("myLinks");
    if (x.style.display === "block") {
        x.style.display = "none";
    } else {
        x.style.display = "block";
    }
}

scrollToSelfHelpCatalog() {
  // self-help-catalog
  this.pageScrollService.scroll({
    document: this.document,
    scrollTarget: '.self-help-catalog'
  });
}
}
