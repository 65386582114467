import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { take, map } from 'rxjs/operators';
import { TaggedResource } from '../models/taggedResource';
import { PaginatedResult } from '../models/pagination';
import { TaggedResources } from '../models/taggedResources';
import { BookmarkedResource } from '../models/bookmarkedResource';
import { EnvironmentService, EnvVar } from 'src/app/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class BookmarkedResourceService {
  private env_vars: EnvVar = null;
  constructor(
    private http: HttpClient,
    private _env_service: EnvironmentService) {
    this.env_vars = this._env_service.getEnvVars();
  }

  createBookmarkedResource(bookmarkedResource: BookmarkedResource) {
    return this.http.post(this.env_vars.services_base_url + "/bookmarkedresources/", bookmarkedResource);
  }

  deleteBookmarkedResource(id: number) {
    return this.http.delete(this.env_vars.services_base_url + '/bookmarkedresources/' + id);
  }

  getBookmarkedResource(resourceId, bookmarkedResourceParams): Observable<BookmarkedResource> {
    let params = new HttpParams();
    if (bookmarkedResourceParams != null) {
      params = params.append('workflowId', bookmarkedResourceParams.workflowId);
      params = params.append('userId', bookmarkedResourceParams.userId);
    }

    return this.http.get<BookmarkedResource>(this.env_vars.services_base_url + '/bookmarkedresources/' + resourceId, { observe: 'response', params: params }).pipe(
      map(response => {
        let bookmarkedResource = response.body as BookmarkedResource;

        return bookmarkedResource;
      })
    );
  }



  getBookmarkedResources(page?, itemsPerPage?, bookmarkedResourceParams?): Observable<PaginatedResult<BookmarkedResource[]>> {
    const paginatedResults: PaginatedResult<BookmarkedResource[]> = new PaginatedResult<BookmarkedResource[]>();

    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }

    if (bookmarkedResourceParams != null) {
      if (bookmarkedResourceParams.workflowId)
        params = params.append('workflowId', bookmarkedResourceParams.workflowId);
      if (bookmarkedResourceParams.userId)
        params = params.append('userId', bookmarkedResourceParams.userId);
    }

    return this.http.get<BookmarkedResource[]>(this.env_vars.services_base_url + '/bookmarkedresources/', { observe: 'response', params }).pipe(
      map(response => {
        paginatedResults.result = response.body;

        if (response.headers.get('Pagination') != null)
          paginatedResults.pagination = JSON.parse(response.headers.get('Pagination'))

        return paginatedResults;
      })
    );
  }
}
