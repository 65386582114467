import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http'
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(error => {
            
            if (error.status === 401) {
                return throwError("Unauthorized");
            }

            if (error instanceof HttpErrorResponse) {
                const applicationError = error.headers.get('Application-Error');
                if (applicationError) {
                    console.error(applicationError);
                    return throwError(applicationError);
                }
                //For .NET Core 2.2 uncomment line below
                //const serverError = error.error.errors;
                const serverError = error.error;
                let modalStateErrors = '';

                if (serverError && typeof serverError === 'object') {
                    for (const item in serverError) {
                        if (serverError[item]) {
                            modalStateErrors += serverError[item] + '\n';
                        }
                    }
                    return throwError(modalStateErrors || serverError || "Server Error");
                }
            }
        })
        )
    }
}

export const ErrorInterceptorProvider = {

    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true

}