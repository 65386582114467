import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EditorProvider } from 'src/app/services/editorProvider';
import { Workflow } from 'src/app/models/workflow';
import { EditorService } from 'src/app/services/editor.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { TagService } from 'src/app/services/tag.service';
import { Tag } from 'src/app/models/tag';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ResourceService } from 'src/app/services/resource.service';
import { ResourcesComponent } from '../resources/resources.component';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap';
import { WorkflowsModalComponent } from '../workflows-modal/workflows-modal.component';
import { AlertifyService } from 'src/app/services/alertify.service';
import { Router } from '@angular/router';
import { Locale } from 'src/app/models/locale';
import { HistoryService } from 'src/app/services/history.service';
import { EnvironmentService, EnvVar } from 'src/app/services/environment.service';
import { LangService } from 'src/app/services/lang.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, OnDestroy {

  @Input()
  bsModalRef: BsModalRef;
  ddlText = 'Select One';
  ddlLanguage: string;
  constJson = `{
    "pages": [
       {
        "name": "page1"
       }
      ]
     }`;
  selectedWorkflow: Workflow;
  selectedWorkflowId = 0;
  workflows: Workflow[];
  workflowsChangeSub: Subscription;
  workflowsAddedSub: Subscription;
  localesSub: Subscription;
  selectedLocale = 'en';
  locales: Locale[];
  private sessionWorkflowStateId = 'WORKFLOWSTATE';

  @ViewChild('selectedFlag', { static: false })
  selectedFlag: HTMLElement;

  private envVars: EnvVar = null;
  private spinnerRefCount = 0;

  constructor(
    private editorProvider: EditorProvider,
    private historyService: HistoryService,
    private editorService: EditorService,
    private workflowService: WorkflowService,
    private tagService: TagService,
    private resourceService: ResourceService,
    private modalService: BsModalService,
    private alertifyService: AlertifyService,
    private http: HttpClient,
    private envService: EnvironmentService,
    private langService: LangService,
    private router: Router,
    private spinnerService: SpinnerService
  ) {
    this.workflows = editorProvider.preLoadWorkflows();
    this.envVars = this.envService.getEnvVars();
  }

  ngOnInit() {
    this.spinnerService.show();
    this.initializeWorkflows();
    this.workflowsChangeSub = this.workflowService.onWorkflowsChange.subscribe(res => {
      this.initializeWorkflows();
    });
    this.workflowsAddedSub = this.workflowService.onWorkflowAdded.subscribe(workflow => {
      this.onWorkflowAdded(workflow.selfhelpWorkflowsId);
    });
    this.localesSub = this.resourceService.getLocales().subscribe(locales => {
      this.locales = locales;
      this.locales = this.locales.filter(l => {
        this.spinnerService.hide();
        if (l.name === 'en') {
          return l;
        }
      });
    });
  }

  //
  // worklfow
  //
  onCreate() {
    const initialState = {
      title: 'Create Workflow'
    };
    this.workflowService.setMode('create');
    this.ddlText = 'Select One';
    this.selectedWorkflow = new Workflow();
    this.selectedWorkflowId = 0;
    this.selectedWorkflow.json = this.constJson;
    this.editorService.bindEditor(this.selectedWorkflow.json);
    this.bsModalRef = this.modalService.show(WorkflowsModalComponent, { initialState });
    this.bsModalRef.setClass('modal-lg');
  }

  onUpdate() {
    const initialState = {
      title: 'Update Workflow'
    };
    this.workflowService.setMode('update');
    this.workflowService.setUpdateWorkflowId(this.selectedWorkflow.selfhelpWorkflowsId);
    this.bsModalRef = this.modalService.show(WorkflowsModalComponent, { initialState });
    this.bsModalRef.setClass('modal-lg');
  }

  onDelete() {
    this.alertifyService.confirm('Are you sure you want to Delete workflow?', () => {
      const url = this.envVars.services_base_url + '/Workflows/deleteWorkflow/' + this.selectedWorkflowId;
      this.http.post(url, {}).subscribe(res => {
        this.workflowService.refresh();
        this.tagService.refresh();
        this.resourceService.refresh();
        this.ddlText = 'Select One';
        this.selectedWorkflow = new Workflow();
        this.selectedWorkflowId = 0;
        this.selectedWorkflow.json = this.constJson;
        this.workflowService.workflowDeleted(this.selectedWorkflow);
        this.editorService.bindEditor(this.selectedWorkflow.json);
        this.alertifyService.success('Deleted');
        this.spinnerService.hide();
      });
    });
  }

  onWorkflowAdded(id) {
    this.spinnerService.show();
    this.workflowService.getWorkflows().subscribe(result => {
      this.workflows = [...result];
      this.onWorkflowSelect(id);
      // this.onGenerateTags(id);
      // this.tagService.refresh();
      // this.resourceService.refresh();
      this.spinnerService.hide();
    });
  }

  initializeWorkflows() {
    this.spinnerService.show();
    this.workflowService.getWorkflows().subscribe(result => {
      this.workflows = result;
      this.spinnerService.hide();
    });
  }

  onWorkflowSelect(id) {
    this.spinnerService.show();
    this.selectedWorkflow = this.workflows.find((workflow) => workflow.selfhelpWorkflowsId === id);
    if (this.selectedWorkflow) {
      this.workflowService.getWorkflow(id, 'en', true).subscribe(workflow => {
        this.selectedWorkflow = workflow;
        this.bindEditor();
        this.spinnerService.hide();
      });
    }
    this.editorService.bindTabs(this.selectedWorkflow);
    this.ddlText = this.selectedWorkflow.name;
    this.selectedWorkflowId = this.selectedWorkflow.selfhelpWorkflowsId;
  }

  bindEditor() {
    this.editorService.bindEditor(this.selectedWorkflow.json);
  }

  onSurveySaved(survey) {
    this.spinnerService.show();
    this.selectedWorkflow.json = JSON.stringify(survey);
    this.workflowService.updateWorkflowJson(this.selectedWorkflow).subscribe(updatedWorkflow => {
      this.selectedWorkflow = updatedWorkflow;
      this.bindEditor();
      this.tagService.getTagsCount(this.selectedWorkflow.selfhelpWorkflowsId).subscribe(count => {
        (0 === count) ?
          this.onGenerateTags(this.selectedWorkflow.selfhelpWorkflowsId) :
          this.onUpdateTags(this.selectedWorkflow.selfhelpWorkflowsId);
        this.spinnerService.hide();
      });
    });
  }

  bindWorkflow(json) {
    this.selectedWorkflow.json = json;
  }

  //
  // tags
  //
  startsWithNumber(text: string) {
    if (text[0] === '0' ||
      text[0] === '1' ||
      text[0] === '2' ||
      text[0] === '3' ||
      text[0] === '4' ||
      text[0] === '5' ||
      text[0] === '6' ||
      text[0] === '7' ||
      text[0] === '8' ||
      text[0] === '9') {
      return true;
    }

    return false;
  }

  getTag(tags: Tag[], tagCode: string): Tag {
    for (let index = 0; index < tags.length; ++index) {
      if (tags[index].tagCode === tagCode) {
        return tags[index];
      }
    }
    // we should nerver get here!!!
    return new Tag();
  }

  onGenerateTags(workflowId) {
    this.spinnerService.show();
    if (this.selectedWorkflow.selfhelpWorkflowsId === workflowId) {
      const choices = this.tagService.etlTags(this.selectedWorkflow.json, 'gen');
      this.tagService.createTags(this.selectedWorkflowId, choices).subscribe(res => {
        this.tagService.refresh();
        this.spinnerService.hide();
      });
    }
  }

  onUpdateTags(workflowId) {
    this.spinnerService.show();
    if (this.selectedWorkflow.selfhelpWorkflowsId === workflowId) {
      this.tagService.getTags(workflowId, this.langService.getLangCode(), true).subscribe(data => {
        const tags = data as Tag[];
        const finalTags: Tag[] = [];
        const choices = this.tagService.etlTags(this.selectedWorkflow.json, 'update');
        choices.forEach((c: string) => {
          if (this.startsWithNumber(c)) {
            // update
            const tagCode = c.substring(0, c.indexOf(']') + 1);
            const t = this.getTag(tags, tagCode);
            t.tagCode = tagCode;
            t.text = c.substring(c.indexOf(']') + 1);
            finalTags.push(t);
          } else {
            // Add
            const t = new Tag();
            t.selfhelpTagsId = 0;
            t.workflowId = workflowId;
            t.masterTextId = 0; // @TODO: remove this
            t.tagCode = '';
            t.text = c;
            t.date_created = new Date();
            t.date_modified = t.date_created;
            t.taggedResourcesCount = 0;
            finalTags.push(t);
          }
        });

        // Remove
        const tagIds = finalTags.map(t => t.selfhelpTagsId);
        tags.forEach(t => {
          if (0 !== t.selfhelpTagsId) {
            if (!tagIds.includes(t.selfhelpTagsId)) {
              t.selfhelpTagsId *= -1;  // mark for removal
              finalTags.push(t);
            }
          }
        });

        this.tagService.updateTags(workflowId, finalTags).subscribe(res => {
          this.tagService.refresh();
          this.spinnerService.hide();
        });
      });
    }
  }

  //
  // misc
  //
  changeTab(e) {
    if (!e.tabset) {
      return;
    } else {
      if (this.selectedWorkflowId > 0) {
        this.tagService.refresh();
        this.resourceService.refresh();
      }
    }
  }

  onPreview(workflowId) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/workflows/' + workflowId]));
    window.open(url, '_blank');
  }

  toTitleCase(word: string) {
    if (!word) {
      return word;
    }
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  // onCopy() {
  //   this.alertifyService.confirm('Are you sure you want to OVERWRITE Workflow?', () => {
  //     this.selectedWorkflow['json' + this.toTitleCase(this.selectedLocale)] = this.selectedWorkflow.json;
  //     // @TODO: fix this to only send workflow, no id is needed
  //     this.workflowService.updateWorkflowJson(this.selectedWorkflow.selfhelpWorkflowsId,
  //       this.selectedWorkflow).pipe(take(1)).subscribe(updatedWorkflow => {
  //         if (this.selectedWorkflow) {
  //           this.bindEditor();
  //         }
  //         this.editorService.bindTabs(this.selectedWorkflow);
  //         this.tagService.deleteTags(this.selectedWorkflow.selfhelpWorkflowsId).subscribe(data => {
  //           this.tagService.refresh();
  //           this.resourceService.refresh();
  //         });
  //       });
  //   });
  // }

  ngOnDestroy(): void {
    if (this.workflowsChangeSub) {
      this.workflowsChangeSub.unsubscribe();
    }
    if (this.workflowsAddedSub) {
      this.workflowsAddedSub.unsubscribe();
    }
    if (this.localesSub) {
      this.localesSub.unsubscribe();
    }
  }
}
