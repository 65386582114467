import { Injectable } from '@angular/core';
import { Workflow } from '../models/workflow';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { EnvironmentService, EnvVar } from 'src/app/services/environment.service';

@Injectable()
export class DissolutionProvider {
    private env_vars: EnvVar = null;
    private json: string;
    locale: string;
    id: number;

    constructor(private http: HttpClient,
        private _env_service: EnvironmentService) {
        this.env_vars = this._env_service.getEnvVars();
    }

    public preLoadJson(): string {
        return this.json;
    }

    load() {
        return new Promise((resolve, reject) => {
            this.http
                .get<Workflow>(this.env_vars.services_base_url + '/workflows/names/' + "dissolution").pipe(take(1))
                .subscribe(response => {
                    this.json = response.json;
                    resolve(true);
                })
        });
    }
}

