import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { LangService } from '../services/lang.service';
import { ContentService } from '../services/content.service';

@Component({
  selector: 'app-langbar',
  templateUrl: './langbar.component.html',
  styleUrls: ['./langbar.component.scss']
})
export class LangbarComponent implements OnInit {
  locales = [];
  constructor(
    private http: HttpClient,
    private langService: LangService,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    const servicesBaseUrl = window.location.origin + '/api';
    this.http.get(servicesBaseUrl + '/Resources/getLocales').subscribe((res: any) => {
      // console.log(res);
      this.locales = res;
      this.locales = this.locales.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1 );
      // console.log(this.locales);
    });
  }

  reloadComponent(qparams: object) {
    // console.log(qparams);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    const url = new URL(location.href);
    // console.log('navigate to: ' + url.pathname);
    this.router.navigate([url.pathname]);
  }

  onLang(lang_code) {
    // console.log($event);
    // console.log(this.curLangCode);
    this.langService.tiggertLangCodeChange(lang_code);
    // console.log('prevLangCode:' + this.prevLangCode);
    // console.log('curLangCode:' + this.curLangCode);
    this.contentService.reload(this.langService.getLangCode());
    this.route.queryParams.subscribe(qparams => {
      this.reloadComponent(qparams);
    });
  }

}
