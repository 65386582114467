import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Tag } from '../models/tag';
import { TaggedResource } from '../models/taggedResource';
import { EnvironmentService, EnvVar } from 'src/app/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class TaggedResourceService {
  private envVars: EnvVar = null;

  constructor(private http: HttpClient,
    private envservice: EnvironmentService) {
    this.envVars = this.envservice.getEnvVars();
  }

  createTaggedResource(workflowId: number, tagId: number, resourceIds: string[]) {
    return this.http.post(this.envVars.services_base_url + '/TaggedResources/createTaggedResources/'
      + workflowId + '/' + tagId, resourceIds);
  }

  updateTaggedResources(workflowId: number, tagId: number, resourceIds: string[]) {
    return this.http.put(this.envVars.services_base_url + '/TaggedResources/updateTaggedResources/'
      + workflowId + '/' + tagId, resourceIds);
  }

  updateResourcesTagged(workflowId: number, resourceId: string, taggedResources: TaggedResource[]) {
    return this.http.put(this.envVars.services_base_url + '/TaggedResources/updateResourcesTagged/'
      + workflowId + '/' + resourceId, taggedResources);
  }

  getTaggedResources(workflowId: number, tagId: number): Observable<TaggedResource[]> {
    return this.http.get<TaggedResource[]>(this.envVars.services_base_url +
      '/TaggedResources/getTaggedResources/' + workflowId + '/' + tagId);
  }

  getResourcesTagged(workflowId: number, langCode: string): Observable<SelfHelpResources.Resource[]> {
    return this.http.get<SelfHelpResources.Resource[]>(this.envVars.services_base_url +
      '/TaggedResources/getResourcesTagged/' + workflowId + '/' + langCode);
  }

  getResourceTags(workflowId: number, selfhelpResourcesId: number) {
    return this.http.get<TaggedResource[]>(this.envVars.services_base_url +
      '/TaggedResources/getResourceTags/' + workflowId + '/' + selfhelpResourcesId);
  }

  deleteResourceTags(workflowId: number, selfhelpResourcesId: number) {
    return this.http.delete<TaggedResource[]>(this.envVars.services_base_url +
      '/TaggedResources/deleteResourceTags/' + workflowId + '/' + selfhelpResourcesId);
  }

  deleteTagResources(workflowId: number, tagId: number) {
    return this.http.delete<Tag>(this.envVars.services_base_url + '/TaggedResources/deleteTagResources/' +
      workflowId + '/' + tagId, {});
  }

  clearTaggedResources(workflowId: number) {
    return this.http.delete<number>(this.envVars.services_base_url + '/TaggedResources/clearTaggedResources/' + workflowId, {});
  }
}
