import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Workflow } from '../models/workflow';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EnvironmentService, EnvVar } from 'src/app/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  private envVars: EnvVar = null;
  private mode = 'create';
  private workflowId: number;
  private workflowsChangeSubject = new Subject();
  onWorkflowsChange = this.workflowsChangeSubject.asObservable();

  private workflowAddSubject = new Subject<Workflow>();
  onWorkflowAdded = this.workflowAddSubject.asObservable();

  private workflowDeleteSubject = new Subject<Workflow>();
  onWorkflowDeleted = this.workflowDeleteSubject.asObservable();

  constructor(
    private http: HttpClient,
    private envService: EnvironmentService) {
    this.envVars = this.envService.getEnvVars();
  }

  setMode(mode: string) {
    this.mode = mode;
  }

  getMode() {
    return this.mode;
  }

  setUpdateWorkflowId(workflowId: number) {
    this.workflowId = workflowId;
  }

  getUpateWorkflowId() {
    return this.workflowId;
  }

  createWorkflow(workflow: Workflow): Observable<Workflow> {
    return this.http.post<Workflow>(this.envVars.services_base_url + '/Workflows/createWorkflow', workflow);
  }

  updateWorkflow(workflow: Workflow): Observable<Workflow> {
    return this.http.post<Workflow>(this.envVars.services_base_url + '/Workflows/updateWorkflow', workflow);
  }

  getWorkflow(id: number, langCode: string, addCode: boolean): Observable<Workflow> {
    return this.http.get<Workflow>(this.envVars.services_base_url + '/Workflows/getWorkflow/' + id + '/' + langCode + '/' + addCode);
  }

  getWorkflows(langCode = 'en', active = -1): Observable<Workflow[]> {
    return this.http
      .get<Workflow[]>(this.envVars.services_base_url + '/Workflows/getWorkflows' + '/' + langCode + '/' + active);
  }

  updateWorkflowJson(workflow: Workflow): Observable<Workflow> {
    return this.http.put<Workflow>(this.envVars.services_base_url + '/workflows/updateWorkflowJson', workflow);
  }

  deleteWorkflow(id: number) {
    return this.http.post(this.envVars.services_base_url + '/Workflows/deleteWorkFlow/' + id, {});
  }

  refresh() {
    this.workflowsChangeSubject.next();
  }

  workflowAdded(workflow: Workflow) {
    this.workflowAddSubject.next(workflow);
  }

  workflowDeleted(workflow: Workflow) {
    this.workflowDeleteSubject.next();
  }
}
