import { Component, OnInit, OnDestroy } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { EnvironmentService, EnvVar } from 'src/app/services/environment.service';
import { LangService } from 'src/app/services/lang.service';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) { }
  transform(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit, OnDestroy {
  help_text = '';
  showChatForm = false;
  langSubscription: Subscription;
  constructor(
    private langService: LangService,
    private envService: EnvironmentService,
    private domSanitizer: DomSanitizer) {
    this.langSubscription = this.langService.onLangChange().subscribe(() => {
      this.help_text = this.langService.getChatbotHelpText();
    });
  }

  ngOnInit() {
    this.help_text = this.langService.getChatbotHelpText();
  }

  getChatbotCaption() {
    return this.langService.getChatbotCaption();
  }

  getURL() {
    let lang_code = '';
    switch (this.langService.getLangCode()) {
      default:
      case 'en': lang_code = 'en'; break;
      case 'es': lang_code = 'es'; break;
    }
    const url = `${this.envService.getEnvVars().chatbot_base_url}?lang=${lang_code}`;
    console.log(url);
    return (url);
  }

  getLangCode() {
    return this.langService.getLangCode();
  }

  openChatbot(flag?: boolean) {
    if (flag) {
      this.showChatForm = flag;
      return;
    }
    this.showChatForm = !this.showChatForm;
  }

  ngOnDestroy() {
    this.langSubscription.unsubscribe();
  }
}
