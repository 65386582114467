import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { Message } from 'src/app/models/message';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { AlertifyService } from 'src/app/services/alertify.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-member-messages',
  templateUrl: './member-messages.component.html',
  styleUrls: ['./member-messages.component.scss']
})
export class MemberMessagesComponent implements OnInit, AfterViewChecked {
  @Input()
  recipientId: number;
  messages: Message[];
  newMessage: any = {};

  @ViewChild('chatBody',{static:false}) 
  private myScrollContainer: ElementRef;

  constructor(private userService: UserService, private authService: AuthService, private alertifyService: AlertifyService) { }

  ngOnInit() {
    this.loadMessages();
    this.scrollToBottom();
  }

  loadMessages() {
    const currentUserId = parseInt(this.authService.decodedToken.nameid);
    this.userService.getMesssageThread(this.authService.decodedToken.nameid, this.recipientId).pipe(
        tap((messages:Message[]) =>{
          for(let i = 0; i < messages.length; i++){
            if(messages[i].isRead === false && messages[i].recipientId === currentUserId){
            }
            this.userService.markAsRead(currentUserId,messages[i].id);
          }
        })).subscribe(

      (messages) => {
        this.messages = messages;
      },
      (error) => {
        this.alertifyService.error(error);
      });
  }

  sendMessage() {
    this.newMessage.recipientId = this.recipientId;
    this.userService.sendMessage(this.authService.decodedToken.nameid, this.newMessage).subscribe((message: Message) => {

      this.messages.push(message);
      this.newMessage.content = '';
      this.scrollToBottom();
    }, (error) => {
      this.alertifyService.error(error);
    });
  }

  scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
}

ngAfterViewChecked() {        
  this.scrollToBottom();        
} 


}
