export class Workflow {
    selfhelpWorkflowsId: number;
    name: string;
    displayName: string;
    description: string;
    json: string;
    date_created: Date;
    date_modified: Date;
}

