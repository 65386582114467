import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { ContentService } from '../../services/content.service';
import { AlertifyService } from '../../services/alertify.service';
import { LangService } from '../../services/lang.service';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit, OnDestroy {
  private sessionResultsId = 'RESULTS';

  title = '';
  workflowId = 0;
  workflowName = '';
  document: Document;
  ColumnMode;

  favEnabled = '/assets/images/favorites-icon-enabled.png';
  favDisabled = '/assets/images/favorites-icon-disabled.png';
  servicesBaseUrl = '';
  userAuthenticated = false;
  userAdGuid = '';
  favorites = [];
  // categories: SelfHelpResources.Category[] = [];
  resources: SelfHelpResources.Resource[] = [];
  topicResources: SelfHelpResources.TopicResource[] = [];
  types: SelfHelpResources.Type[] = [];
  // topics: SelfHelpResources.Topic[] = [];

  routerSub: Subscription;
  // subCategory: Subscription;
  subTopicResources: Subscription;
  subTypes: Subscription;
  // subTopics: Subscription;

  constructor(
    private resourcesService: ResourceService,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private location: Location,
    private alertifyService: AlertifyService,
    private langService: LangService,
    private http: HttpClient) {
    this.resourcesService.onResults.subscribe(resources => {
      if (null != resources) {
        this.resources = resources.sort((a, b) => (this.getRankResource(a.resourceId) > this.getRankResource(b.resourceId)) ? 1 : -1);
        // console.log(this.resources);
        this.initUserFavorites();
        // this.setToSessionStorage(this.sessionResultsId);
      }
    });
  }

  ngOnInit() {
    // console.log('nginit called');
    this.langService.disableLang();
    this.routerSub = this.route.params.subscribe(params => {
      if (params['name']) {
        this.title = params['name'];
      }
      if (params['id']) {
        this.workflowId = +params['id'] as number;
      }
    });

    this.subTopicResources = this.contentService.getResourcesTopics().subscribe(result => {
      this.topicResources = result.TopicResource;
    });

    this.subTypes = this.contentService.getTypes().subscribe(types => {
      this.types = types.Type;
      const reourceTypes = this.resources.map(r => r.typeId);
      this.types = this.types.filter(t => reourceTypes.includes(t.typeId));
      this.types = this.types.sort((a, b) => (a.rankType > b.rankType) ? 1 : -1);
      console.log(this.types);
    });

    // this.subTopics = this.contentService.getTopics().subscribe(result => {
    //   this.topics = result.Topic.sort((a, b) => (a.rankTopic > b.rankTopic) ? 1 : -1);
    // });

    // this.subCategory = this.contentService.getCategories().subscribe(result => {
    //   this.categories = result.Category;
    // });

    // const ssRows = this.getItemFromSessionStorage(this.sessionResultsId) as SelfHelpResources.Resource[];
    // if (ssRows) {
    //   this.resources = ssRows;
    // }
  }

  initUserFavorites() {
    this.servicesBaseUrl = window.location.origin + '/api';
    let url = this.servicesBaseUrl + '/Authentication/getUserInfo';
    this.http.get(url).subscribe((resp1: any) => {
      this.userAuthenticated = Boolean(resp1.isAuthenticated);
      if (this.userAuthenticated) {
        url = this.servicesBaseUrl + '/Authentication/getUserProfile';
        this.http.get(url).subscribe((resp2: any) => {
          this.userAdGuid = resp2.guid;
          url = this.servicesBaseUrl + '/Authentication/getUserFavorites/' + this.userAdGuid;
          this.http.get(url).subscribe((resp3: any) => {
            this.favorites = resp3;
            this.resources.forEach(r => {
              let found = false;
              for (const fv of this.favorites) {
                if (fv.selfhelpResourcesId === r.selfhelpResourcesId) {
                  found = true;
                  break;
                }
              }
              r.img = (found) ? this.favEnabled : this.favDisabled;
            });
          });
        });
      }
    });
  }

  onFavorite(resource: SelfHelpResources.Resource) {
    resource.img = (this.favDisabled === resource.img) ? this.favEnabled : this.favDisabled;
    if (this.favEnabled === resource.img) {
      const url = this.servicesBaseUrl + '/Authentication/addUserFavorite/' + this.userAdGuid + '/' + resource.selfhelpResourcesId;
      this.http.post(url, {}).subscribe((resp: any) => {
        if (200 === resp.statusCode) {
          this.alertifyService.success('User favorite add succeeded.');
        } else {
          this.alertifyService.error('User favorite add failed.');
        }
        this.initUserFavorites();
      });
    } else {
      const fv = this.favorites.find(f => f.selfhelpResourcesId === resource.selfhelpResourcesId && f.userAdGuid === this.userAdGuid);
      const url = this.servicesBaseUrl + '/Authentication/removeUserFavorite/' + fv.selfhelpFavoritesId;
      this.http.post(url, {}).subscribe((resp: any) => {
        if (200 === resp.statusCode) {
          this.alertifyService.success('User favorite remove succeeded.');
        } else {
          this.alertifyService.error('User favorite remove failed.');
        }
        this.initUserFavorites();
      });
    }
  }

  onRemove(resource: SelfHelpResources.Resource) {
    const fv = this.favorites.find(f => f.selfhelpResourcesId === resource.selfhelpResourcesId);
    const url = this.servicesBaseUrl + '/api/Authentication/removeUserFavorite/' + fv.selfhelpFavoritesId;
    this.http.post(url, {}).subscribe((resp: any) => {
      if (200 === resp.statusCode) {
        this.alertifyService.success('User favorite remove succeeded.');
      } else {
        this.alertifyService.error('User favorite remove failed.');
      }
      this.initUserFavorites();
    });
  }

  // getCategoryId(resource: SelfHelpResources.Resource) {
  //   const fv = this.favorites.find(f => f.selfhelpResourcesId === resource.selfhelpResourcesId);
  //   return (null === fv || undefined === fv) ? 'C01' : fv.categoryId;
  // }

  // getTopicId(resource: SelfHelpResources.Resource) {
  //   const fv = this.favorites.find(f => f.selfhelpResourcesId === resource.selfhelpResourcesId);
  //   return (null === fv || undefined === fv) ? 'Topic001' : fv.topicId;
  // }

  getResourceType(typeId): SelfHelpResources.Type {
    return this.types.find(x => x.typeId === typeId);
  }

  filterRows(resources, typeId) {
    return resources.filter(x => x.typeId === typeId);
  }

  isExist(typeId) {
    if (this.resources.length < 0) {
      return false;
    } else {
      return this.resources.filter(x => x.typeId === typeId).length > 0;
    }
  }

  getRankResource(resourceId) {
    const tr = this.topicResources.find(r => r.resourceId === resourceId);
    return (undefined === tr) ? 0 : tr.rankResource;
  }

  goToLink(resource: SelfHelpResources.Resource) {
    window.open(resource.url, '_blank');
  }

  goBack() {
    this.location.back();
  }

  startOver() {
    sessionStorage.clear();
    this.location.back();
  }

  setToSessionStorage(itemKey) {
    sessionStorage.setItem(itemKey, JSON.stringify(this.resources));
  }

  getItemFromSessionStorage(itemKey) {
    return JSON.parse(sessionStorage.getItem(itemKey));
  }

  toTitleCase(word: string) {
    if (!word) {
      return word;
    }
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    if (this.subTypes) {
      this.subTypes.unsubscribe();
    }
    if (this.subTopicResources) {
      this.subTopicResources.unsubscribe();
    }
    // if (this.subTopics) {
    //   this.subTopics.unsubscribe();
    // }
    // if (this.subCategory) {
    //   this.subCategory.unsubscribe();
    // }
  }
}
