import { Injectable } from '@angular/core';
import { PageHistory } from '../models/pagehistory';
import { Subject } from 'rxjs';
import { Workflow } from '../models/workflow';
import { Locale } from '../models/locale';


@Injectable({
  providedIn: 'root'
})
export class EditorService {
  private editorSubject = new Subject<string>();
  editorChanged = this.editorSubject.asObservable();
  
  private workflowSubject = new Subject<Workflow>();
  workflowChanged = this.workflowSubject.asObservable();
 

  constructor() { }

  bindEditor(json) {
    this.editorSubject.next(json);
  }

  bindTabs(workflow:Workflow){
    this.workflowSubject.next(workflow)
  }
}
