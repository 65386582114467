import { BrowserModule, } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpExceptionInterceptor } from './http-exception.interceptor';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SurveyCreatorComponent } from './survey.creator.component';
import { SurveyComponent } from './survey.component';
import { AdminPanelComponent } from './admin/admin-panel/admin-panel.component';
import { EditorComponent } from './admin/editor/editor.component';
import { CategoriesComponent } from './categories/categories.component';
import { NavComponent } from './nav/nav.component';
import { HomeComponent } from './home/home.component';
import { HistoryComponent } from './workflows/history/history.component';
import { workflowProviderFactory } from './services/workflowProviderFactory';
import { DissolutionProvider } from './services/dissolutionProvider';
import { EditorProvider } from './services/editorProvider';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule, ButtonsModule } from 'ngx-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RegisterComponent } from './register/register.component';
import { ErrorInterceptorProvider } from './services/error.interceptor';
import { RolesModalComponent } from './admin/roles-modal/roles-modal.component';
import { TimeAgoPipe } from 'time-ago-pipe';
import { HasRoleDirective } from './directives/has-role/has-role.directive';
import { MemberListComponent } from './members/member-list/member-list.component';
import { MemberMessagesComponent } from './members/member-messages/member-messages.component';
import { MemberCardComponent } from './members/member-card/member-card.component';
import { MemberEditComponent } from './members/member-edit/member-edit.component';
import { MemberDetailComponent } from './members/member-detail/member-detail.component';
import { PhotoEditorComponent } from './members/photo-editor/photo-editor.component';
import { MessagesComponent } from './messages/messages.component';
import { FileUploadModule } from 'ng2-file-upload';
import { MemberDashboardComponent } from './members/member-dashboard/member-dashboard.component';
import { MemberDetailResolver } from './resolvers/member-detail.resolver';
import { MemberEditResolver } from './resolvers/member-edit.resolver';
import { MessagesResolver } from './resolvers/messages.resolver';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { jwtOptionsFactory } from './services/jwtOptionsFactory';
import { ResultsComponent } from './workflows/results/results.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ResourcesComponent } from './admin/resources/resources.component';
import { TagsComponent } from './admin/tags/tags.component';
import { ResourcesModalComponent } from './admin/resources-modal/resources-modal.component';
import { TagsModalComponent } from './admin/tags-modal/tags-modal.component';
import { WorkflowsModalComponent } from './admin/workflows-modal/workflows-modal.component';
import { ResourceEditModalComponent } from './admin/resource-edit-modal/resource-edit-modal.component';
import { CacheInterceptor } from './services/cacheInterceptor';
import { TruncatePipe } from './pipes/truncate.pipe';
import { CountdownPipe } from './pipes/countdown.pipe';
import { CountupPipe } from './pipes/countup.pipe';
import { ResetPasswordComponent } from './members/reset-password/reset-password.component';
import { EmailConfirmComponent } from './members/email-confirm/email-confirm.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { ServiceCatalogComponent } from './service-catalog/service-catalog.component';
import { DetailsComponent } from './details/details.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { OrderModule } from 'ngx-order-pipe';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { UserProfileComponent } from './userprofile/userprofile.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { FavoritesComponent } from './favorites/favorites.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { ImportComponent } from './import/import.component';
import { LangbarComponent } from './langbar/langbar.component';
import { AngularTreeTableModule } from 'angular-tree-table';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { SafePipe } from 'src/app/chatbot/chatbot.component';


@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    RegisterComponent,
    AdminPanelComponent,
    SurveyComponent,
    SurveyCreatorComponent,
    EditorComponent,
    CategoriesComponent,
    HomeComponent,
    HistoryComponent,
    RolesModalComponent,
    TimeAgoPipe,
    HasRoleDirective,
    FooterComponent,
    MemberCardComponent,
    MemberDetailComponent,
    MemberEditComponent,
    MemberListComponent,
    MemberMessagesComponent,
    PhotoEditorComponent,
    MessagesComponent,
    MemberDashboardComponent,
    ResultsComponent,
    ResourcesComponent,
    TagsComponent,
    ResourcesModalComponent,
    TagsModalComponent,
    WorkflowsModalComponent,
    ResourceEditModalComponent,
    TruncatePipe,
    CountdownPipe,
    CountupPipe,
    ResetPasswordComponent,
    EmailConfirmComponent,
    SearchResultsComponent,
    ServiceCatalogComponent,
    DetailsComponent,
    SidenavComponent,
    UserProfileComponent,
    FavoritesComponent,
    ImportComponent,
    LangbarComponent,
    ChatbotComponent,
    SafePipe
  ],
  exports: [
    NgxPageScrollCoreModule,
  ],
  imports: [
    BrowserModule,
    OrderModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    NgxDatatableModule,
    ButtonsModule.forRoot(),
    PaginationModule.forRoot(),
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    JwtModule.forRoot({}),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    PerfectScrollbarModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    NgxSpinnerModule,
    AngularTreeTableModule
  ],
  providers: [
    ErrorInterceptorProvider,
    MemberDetailResolver,
    MemberEditResolver,
    MessagesResolver,
    DissolutionProvider,
    EditorProvider,
    // { provide: APP_INITIALIZER, useFactory: workflowProviderFactory, deps: [DissolutionProvider], multi: true },
    { provide: APP_INITIALIZER, useFactory: workflowProviderFactory, deps: [EditorProvider], multi: true },
    { provide: JWT_OPTIONS, useFactory: jwtOptionsFactory, deps: [], multi: false },
    { provide: HTTP_INTERCEPTORS, useClass: HttpExceptionInterceptor, multi: true }
  ],
  entryComponents: [
    RolesModalComponent,
    ResourcesModalComponent,
    TagsModalComponent,
    WorkflowsModalComponent,
    ResourceEditModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
