import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from '@angular/core';
import * as SurveyKo from 'survey-knockout';
import * as SurveyCreator from 'survey-creator';
import * as widgets from 'surveyjs-widgets';

import 'inputmask/dist/inputmask/phone-codes/phone.js';
import { EditorService } from './services/editor.service';

widgets.icheck(SurveyKo);
widgets.select2(SurveyKo);
widgets.inputmask(SurveyKo);
widgets.jquerybarrating(SurveyKo);
widgets.jqueryuidatepicker(SurveyKo);
widgets.nouislider(SurveyKo);
widgets.select2tagbox(SurveyKo);
widgets.signaturepad(SurveyKo);
widgets.sortablejs(SurveyKo);
widgets.ckeditor(SurveyKo);
widgets.autocomplete(SurveyKo);
widgets.bootstrapslider(SurveyKo);
// widgets.emotionsratings(SurveyKo);

SurveyCreator.StylesManager.applyTheme('default');

const CkEditorModalEditor = {
  afterRender: (modalEditor, htmlElement) => {
    const editor = window['CKEDITOR'].replace(htmlElement);
    editor.on('change', () => {
      modalEditor.editingValue = editor.getData();
    });
    editor.setData(modalEditor.editingValue);
  },
  destroy: (modalEditor, htmlElement) => {
    const instance = window['CKEDITOR'].instances[htmlElement.id];
    if (instance) {
      instance.removeAllListeners();
      window['CKEDITOR'].remove(instance);
    }
  }
};
SurveyCreator.SurveyPropertyModalEditor.registerCustomWidget(
  'html',
  CkEditorModalEditor
);

@Component({
  selector: 'app-survey-creator',
  template: `<div id="surveyCreatorContainer"></div>`
})
export class SurveyCreatorComponent implements OnInit {
  surveyCreator: SurveyCreator.SurveyCreator;

  constructor(private editorService: EditorService) { }

  @Output() surveySaved: EventEmitter<object> = new EventEmitter();
  ngOnInit() {
    SurveyKo.JsonObject.metaData.addProperty(
      'questionbase',
      'popupdescription:text'
    );
    SurveyKo.JsonObject.metaData.addProperty('page', 'popupdescription:text');

    const options = { showEmbededSurveyTab: true, generateValidJSON: true };
    this.surveyCreator = new SurveyCreator.SurveyCreator(
      'surveyCreatorContainer',
      options
    );
    const radioGroupControl = this.surveyCreator.toolbox.getItemByName('radiogroup');
    this.surveyCreator.toolbox.clearItems();
    this.surveyCreator.toolbox.addItem(radioGroupControl);
    this.surveyCreator.saveSurveyFunc = this.saveSurvey;
    this.surveyCreator.hideAdvancedSettings = true;
    this.editorService.editorChanged.subscribe((json) => {
      this.surveyCreator.text = json;
    });
  }

  saveSurvey = () => {
    // console.log(JSON.stringify(this.surveyCreator.text));
    const surveyToSave = JSON.parse(this.surveyCreator.text);
    this.surveySaved.emit(surveyToSave);
  }
}
