import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LangService {
    private langCodeSubject = new Subject<any>();
    private langDisabledSubject = new Subject<any>();
    private langCode = 'en';
    private langDisabled = false;

    tiggertLangCodeChange(langCode: string) {
        this.langCode = langCode;
        this.langCodeSubject.next();
    }
    onLangChange(): Observable<any> {
        return this.langCodeSubject.asObservable();
    }
    getLangCode() {
        return this.langCode;
  }

  getChatbotCaption() {
    let text = '';
    switch (this.getLangCode()) {
      default:
      case 'en': text = 'Self-Help Chat'; break;
      case 'es': text = 'Charla de Autoayuda'; break;
    }
    return text;
  }

  getChatbotHelpText() {
    let text = '';
    switch (this.getLangCode()) {
      default:
      case 'en': text = 'Need Help?';       break;
      case 'es': text = '¿Necesita Ayuda?'; break;
    }
    return text;
  }

    tiggerLangDisabledChange() {
        this.langDisabledSubject.next();
    }
    onLangDisabledChange(): Observable<any> {
        return this.langDisabledSubject.asObservable();
    }
    isLangDisabled(): boolean {
        return this.langDisabled;
    }

    disableLang() {
        this.langDisabled = true;
        this.tiggerLangDisabledChange();
    }

    enableLang() {
        this.langDisabled = false;
        this.tiggerLangDisabledChange();
    }
}
