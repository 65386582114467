import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { User } from 'src/app/models/user';
import { EnvironmentService, EnvVar } from 'src/app/services/environment.service';
import { LangService } from 'src/app/services/lang.service';

export class GAEvent {
  categoryName: string;
  action: string;
  label: string;
}

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  private envVars: EnvVar = null;
  private categories: SelfHelpResources.Category[];
  private topics: SelfHelpResources.Topic[];
  private resources: SelfHelpResources.Resource[];
  private langCode = 'en';
  constructor(private http: HttpClient,
              private langService: LangService,
              private envService: EnvironmentService) {
    this.envVars = this.envService.getEnvVars();
    this.getCategories().subscribe(result => { this.categories = result.Category; });
    this.getTopics().subscribe(result => { this.topics = result.Topic; });
    this.getResources().subscribe(result => { this.resources = result.Resource; });
  }

  //
  // Non-Multilingual
  //
  getResourcesTopics() {
    const url = this.envVars.services_base_url + '/Content/getResourcesTopics';
    return this.http.get<SelfHelpResources.RootObjectTopicResources>(url);
  }
  getCategoriesTopics() {
    const url = this.envVars.services_base_url + '/Content/getCategoriesTopics';
    return this.http.get<SelfHelpResources.RootObjectCategoryTopic>(url);
  }

  //
  // Multilingual
  //
  getAnnouncements(langCode?: string) {
    if (undefined === langCode) {
      langCode = this.langCode;
    }
    const url = this.envVars.services_base_url + `/Content/getAnnouncements?lang_code=${langCode}`;
    return this.http.get<SelfHelpResources.Announcement[]>(url);
  }
  getTexts(langCode?: string) {
    if (undefined === langCode) {
      langCode = this.langCode;
    }
    const url = this.envVars.services_base_url + `/Content/getTexts?langCode=${langCode}`;
    return this.http.get<SelfHelpResources.TextsObject>(url);
  }
  getCategories() {
    const url = this.envVars.services_base_url + `/Content/getCategories?langCode=${this.langCode}`;
    return this.http.get<SelfHelpResources.RootObjectCategory>(url);
  }
  getResources() {
    const url = this.envVars.services_base_url + `/Content/getResources?langCode=${this.langCode}`;
    return this.http.get<SelfHelpResources.RootObjectSelfHelpResources>(url);
  }
  getTypes() {
    const url = this.envVars.services_base_url + `/Content/getTypes?langCode=${this.langCode}`;
    return this.http.get<SelfHelpResources.RootObjectType>(url);
  }
  getTopics() {
    const url = this.envVars.services_base_url + `/Content/getTopics?langCode=${this.langCode}`;
    return this.http.get<SelfHelpResources.RootObjectTopic>(url);
  }

  reload(langCode: string) {
    this.langCode = langCode;
    this.getTexts();
    this.getResources();
    this.getTypes();
    this.getCategories();
    this.getTopics();
  }

  //
  // Misc
  //
  getUrl(url) {
    return this.envVars.services_base_url + '/' + url;
  }

  getGAevent(categoryId: string, topicId: string, resourceId: string): GAEvent {
    const gaEvent: GAEvent = new GAEvent();

    // SER STORY 3075
    gaEvent.categoryName = categoryId;
    const langCode = this.langService.getLangCode();
    gaEvent.action = `${categoryId} | ${topicId} (lang:${langCode})`;
    gaEvent.label = `${resourceId} (lang:${langCode})`;

    return gaEvent;
  }
}
