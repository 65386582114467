import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {
    private spinnerRefCount = 0;
    constructor(private ngxSpinnerService: NgxSpinnerService) {
    }

    show() {
        this.spinnerRefCount++;
        this.ngxSpinnerService.show();
    }

    hide() {
        if (this.spinnerRefCount > 0) {
            this.spinnerRefCount--;
        }
        if (0 === this.spinnerRefCount) {
            this.ngxSpinnerService.hide();
        }
    }

    cancel() {
        this.spinnerRefCount = 0;
        this.hide();
    }

}
