import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

export class EmailMessage {
from
to
subject
templateId
templateContent
htmlContent
includeTemplate
templateDictionary: {[key: string]: string}

constructor() {}
   
}
