import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormControl, FormGroupDirective, FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AlertifyService } from '../services/alertify.service';
import { map } from 'rxjs/operators';

class User {
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  guid: string;
}

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.css']
})
export class UserProfileComponent implements OnInit {
  servicesBaseUrl = '';
  user: User = new User();

  inProgress = false;
  progressLabel = 'updating your profile...';
  error = false;
  errorMessage = '';
  isExpired: boolean;
  enable = false;
  isEditing = false;

  // Input with a custom ErrorStateMatcher
  displayNameFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^[^\/\#]+$/)
  ]);

  firstNameFormControl = new FormControl(this.user.firstName, [
    Validators.required,
    Validators.pattern(/^[^\/\#]+$/)
  ]);

  lastNameFormControl = new FormControl(this.user.lastName, [
    Validators.required,
    Validators.pattern(/^[^\/\#]+$/)
  ]);

  phoneFormControl = new FormControl(this.user.phone, [
    Validators.pattern(/^[0-9]{10}$/)
  ]);

  displayNameMatcher = new DisplayNameErrorStateMatcher();
  firstNameMatcher = new FirstNameErrorStateMatcher();
  lastNameMatcher = new LastNameErrorStateMatcher();
  phoneNumberMatcher = new PhoneNumberErrorStateMatcher();

  constructor(
    private alertifyService: AlertifyService,
    public router: Router,
    private http: HttpClient) {
    this.servicesBaseUrl = window.location.origin + '/api';
    const url = this.servicesBaseUrl + '/Authentication/getUserProfile';
    this.http.get(url).subscribe((resp: any) => {
      this.user = resp;
      // console.log(this.user);
    });
  }

  ngOnInit(): void {
  }

  editProfile() {
    this.displayNameFormControl.setValue(this.user.displayName);
    this.firstNameFormControl.setValue(this.user.firstName);
    this.lastNameFormControl.setValue(this.user.lastName);
    this.phoneFormControl.setValue(this.user.phone);
    this.isEditing = true;
  }

  clearError() {
  }

  updateUserProfile() {
    this.inProgress = true;
    const url = this.servicesBaseUrl + '/Authentication/setUserProfile';
    this.user.displayName = this.displayNameFormControl.value;
    this.user.firstName = this.firstNameFormControl.value;
    this.user.lastName = this.lastNameFormControl.value;
    this.user.phone = this.phoneFormControl.value;
    this.http.post(url, this.user).subscribe((response: any) => {
      this.inProgress = false;
      this.close(response.statusCode);
    });
  }

  cancelUpdate() {
    this.isEditing = false;
  }

  close(rc?: number) {
    if (undefined !== rc) {
      const signOffUrl = this.servicesBaseUrl + '/Authentication/LogOff';
      window.location.href = signOffUrl;
      if (200 === rc) {
        this.alertifyService.success('User profile update succeeded.');
      } else {
        this.alertifyService.error('User profile update failed.');
      }
    } else {
      this.router.navigate(['/']);
    }
  }
}

export class DisplayNameErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export class FirstNameErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export class LastNameErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export class PhoneNumberErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
