import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { environment } from '../../environments/environment';
import { ContentService } from '../services/content.service';
import { AlertifyService } from '../services/alertify.service';

declare var ga;

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {
  servicesBaseUrl = '';
  userAuthenticated = false;
  userAdGuid = '';
  favorites = [];

  textsObject: SelfHelpResources.TextsObject;
  types: SelfHelpResources.Type[] = [];
  resources: SelfHelpResources.Resource[] = [];
  topicResources: SelfHelpResources.TopicResource[] = [];

  constructor(private contentService: ContentService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private alertifyService: AlertifyService) {
    this.servicesBaseUrl = window.location.origin + '/api';
    this.contentService.getTexts().subscribe(result => {
      this.textsObject = result[0];
    });
    const url = this.servicesBaseUrl + '/Authentication/getUserProfile';
    this.http.get(url).subscribe((resp2: any) => {
      this.userAdGuid = resp2.guid;
    });
  }

  ngOnInit() {
    this.contentService.getTypes().subscribe(result => {
      this.types = result.Type.sort((a, b) => (a.rankType > b.rankType) ? 1 : -1);
    });
    this.contentService.getResourcesTopics().subscribe(result => {
      this.topicResources = result.TopicResource;
      this.initFavoriteResources();
    });
  }

  initFavoriteResources() {
    let url = this.servicesBaseUrl + '/Authentication/getUserFavorites/' + this.userAdGuid;
    this.http.get(url).subscribe((resp: any) => {
      this.favorites = resp;
    });
    url = this.servicesBaseUrl + '/Content/getFavoriteResources/' + this.userAdGuid;
    this.http.get(url).subscribe((resp: any) => {
      this.resources = resp;
      this.resources = this.resources.sort((a, b) => (this.getRankResource(a.resourceId) > this.getRankResource(b.resourceId)) ? 1 : -1);
      // console.log(this.resources);
    });
  }

  onRemove(resource: SelfHelpResources.Resource) {
    const fv = this.favorites.find(f => f.selfhelpResourcesId === resource.selfhelpResourcesId);
    const url = this.servicesBaseUrl + '/Authentication/removeUserFavorite/' + fv.selfhelpFavoritesId;
    this.http.post(url, {}).subscribe((resp: any) => {
      if (200 === resp.statusCode) {
        this.alertifyService.success('User favorite remove succeeded.');
      } else {
        this.alertifyService.error('User favorite remove failed.');
      }
      this.initFavoriteResources();
    });
  }

  getCategoryId(resource: SelfHelpResources.Resource) {
    const fv = this.favorites.find(f => f.selfhelpResourcesId === resource.selfhelpResourcesId);
    return fv.categoryId;
  }

  getTopicId(resource: SelfHelpResources.Resource) {
    const fv = this.favorites.find(f => f.selfhelpResourcesId === resource.selfhelpResourcesId);
    return fv.topicId;
  }

  getResourceType(typeId): SelfHelpResources.Type {
    return this.types.find(x => x.typeId === typeId);
  }

  filterRows(resources, typeId) {
    return resources.filter(x => x.typeId === typeId);
  }

  isExist(typeId) {
    if (this.resources.length < 0) {
      return false;
    } else {
      return this.resources.filter(x => x.typeId === typeId).length > 0;
    }
  }

  getRankResource(resourceId) {
    const tr = this.topicResources.find(r => r.resourceId === resourceId);
    return (undefined === tr) ? 0 : tr.rankResource;
  }

  goToLink(resource: SelfHelpResources.Resource) {
    window.open(resource.url, '_blank');
  }
}
