import { Injectable } from '@angular/core';
import { Workflow } from '../models/workflow';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { WorkflowService } from 'src/app/services/workflow.service';
import { EnvironmentService, EnvVar } from 'src/app/services/environment.service';

@Injectable()
export class EditorProvider {
    private envVars: EnvVar = null;
    private workflows: Workflow[];

    constructor(private http: HttpClient,
                private workflowService: WorkflowService,
                private envService: EnvironmentService) {
        this.envVars = this.envService.getEnvVars();
    }

    public preLoadWorkflows(): Workflow[] {
        return this.workflows;
    }

    load() {
        this.envVars = this.envService.getEnvVars();
        return new Promise((resolve, reject) => {
            this.workflowService.getWorkflows()
                .subscribe(response => {
                    this.workflows = response;
                    resolve(true);
                });
        });
    }
}

